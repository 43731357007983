.trade_info {
  margin-top: 50px;
  .trade_card {
    padding: 50px 60px 60px 60px;
    .ant-card-body {
      padding: 0;
    }
    .heading {
      margin-top: 10px;
    }
    .btn-col {
      margin-top: 16px;
    }
    .shido-images {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .trade_info {
    .trade_card {
      padding: 36px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .trade_info {
    .trade_card {
      padding: 36px 12px;
    }
  }
}

// @media (min-width: 993px) and (max-width: 1199px) {
//   .trade_card {
//     padding: 70px 50px;
//     }
// }

@media (min-width: 768px) and (max-width: 992px) {
  .trade_info {
    .trade_card {
      padding: 30px 20px;
    }
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .trade_info {
    .trade_card {
      padding: 30px 20px;
    }
  }
}

@media (min-width: 200px) and (max-width: 576px) {
  .trade_info {
    .trade_card {
      padding: 20px;
    }
  }
}
