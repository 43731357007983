@import "../../../Assets/Theme/_var.scss";

.connect_wallet {
  .modal-content {
    border: 1px solid $border-clr;
    border-radius: 20px;
    background: $gradient;
    text-align: center;

    .modal-header {
      .btn-close {
        // filter: invert(1);

        &:focus {
          box-shadow: unset;
        }
      }

      // .modal-title {
      //   color: $light-blue !important;
      // }
    }

    .modal-body {
      .modal_list {
        padding: unset;

        h6 {
          text-align: left;
          opacity: 1 !important;
          margin-bottom: 2rem;

          &.choose_wallet_title {
            span {
              // opacity: 0.5;
              font-weight: 400;
            }
          }
        }
        .choose_wallet_title_number {
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          display: inline-flex;
          background-color: $white;
          color: $baseColor;
          margin-right: 8px;
          align-items: center;
          justify-content: center;
        }

        .Choose_Network {
          margin-bottom: 0rem;
          text-transform: uppercase;
          color: $white;
        }

        &__item {
          cursor: default !important;
          border-bottom: 1px solid $border-clr;
          justify-content: space-between;
          padding: 1.3rem 2rem;
          transition: all linear 0.2s;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:first-child {
            margin-top: unset;
          }

          &:last-child {
            border-bottom: 0;
          }

          &:hover {
            border-color: $light-blue;
          }

          &--disable {
            cursor: not-allowed !important;
            opacity: 0.6;
            .form-check {
              .form-check-label {
                cursor: not-allowed !important;
              }
            }
          }

          .choose_Icon_Name {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 1.5rem;

            p {
              margin-bottom: 0;
              padding-left: 1rem;
            }
          }

          @media (max-width: 480px) {
            padding: 0.5rem 2rem;
            border-radius: 1rem;
            margin-top: 1rem;
          }

          p {
            margin: 0;
          }

          &__icon {
            width: 35px;

            img {
              width: 35px;
              height: 35px;
              object-fit: cover;

              // &.small_wallet_icon {
              //   width: 4rem;
              //   height: 4rem;

              //   @media (max-width: 767px) {
              //     width: 3.4rem;
              //     height: 3.4rem;
              //   }
              // }
            }
          }

          .ratioBtn {
            width: 16px;
            height: 16px;
            border: 1px solid $primary-clr;
            border-radius: 10rem;
            position: relative;
            display: inline-block;
          }

          &:hover {
            .ratioBtn::after {
              width: 10px;
              height: 10px;
              content: "";
              position: absolute;
              left: 50%;
              top: 50%;
              background-color: $light-blue;
              border-radius: 10rem;
              transform: translate(-50%, -50%);
            }
          }

          .form-check {
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            width: calc(100% - 35px);
            padding-left: 1.5rem;

            .form-check-input {
              background-color: transparent;
              border: 1px solid $light-blue;
              width: 17px;
              height: 17px;

              &:checked[type="radio"] {
                border: 0;
                position: relative;
                background-image: url(../../../Assets/Images/checked_icon.svg);
                box-shadow: unset;
              }
            }

            .form-check-label {
              display: block;
              width: 100%;
              text-align: left;
              cursor: pointer;
              font-size: 1.6rem;
              font-weight: 500;

              @media (max-width: 767px) {
                font-size: 1.3rem;
              }
            }
          }
        }
      }

      .Choose_Wallet {
        margin-top: 2rem;
      }
    }
  }
}

.wallet_checkOut {
  margin-top: 2rem;
  text-align: left;

  label {
    font-size: 14px;

    a {
      color: $light-blue;
    }
  }
}
