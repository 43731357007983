@import "../../../Assets/Theme/_var.scss";

.Containt {
  cursor: pointer;
  margin: 30px auto 20px;
  background: $Bg-Liquidity;
  border-radius: 20px;
  padding: 28px 50px;

  @media (max-width: 575px) {
    padding: 28px 20px;
  }

  .BarProgress {
    max-width: 75%;
    margin-right: auto;

    @media (max-width: 991px) {
      max-width: 100%;
      margin-bottom: 20px;
    }

    .form-label {
      font-size: 18px;
      color: $white;
      font-weight: 500;
      margin-bottom: 0px;
      font-family: "Poppins";
    }

    > div {
      .prog {
        background: $baseColor 0% 0% no-repeat padding-box;
        height: 4px;
        border-radius: 5px;
        opacity: 1;
        width: 100%;
      }

      .SwapBar {
        .progress-bar {
          background: $BarInput 0% 0% no-repeat padding-box;
          border-radius: 5px;
        }
      }

      .DecayBar {
        .progress-bar {
          background: $Bar-Orange 0% 0% no-repeat padding-box;
          border-radius: 5px;
        }
      }

      .ReferralBar {
        .progress-bar {
          background: $Bar-Lightred 0% 0% no-repeat padding-box;
          border-radius: 5px;
        }
      }

      .TreasuryBar {
        .progress-bar {
          background: $Bar-Lightgreen 0% 0% no-repeat padding-box;
          border-radius: 5px;
        }
      }

      .PriceBar {
        .progress-bar {
          background: $Bar-DefaltBlue 0% 0% no-repeat padding-box;
          border-radius: 5px;
        }
      }

      .form-control {
        background: transparent;
        border: none;
        width: 120px;
        padding: 10px;
        margin-left: 10px;
        opacity: 1;
        font-size: 20px;
        font-weight: 500;
        font-family: "Poppins";

        &:focus {
          border: none;
          outline: none;
          box-shadow: none;
        }

        @media (max-width: 767px) {
          width: 80px;
          padding: 10px;
          margin-left: 10px;
          opacity: 1;
          font-size: 14px;
        }
      }

      .TextSwap {
        color: $BarInput;

        &::placeholder {
          color: $BarInput;
        }
      }
    }
  }

  .TotleValues {
    max-width: 75%;
    margin-left: auto;

    @media (max-width: 991px) {
      max-width: 100%;
    }

    .ValueList {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      // margin-bottom: 20px;
      line-height: 33px;

      .labelHed {
        font-size: 14px;
        color: $white;
        opacity: 0.6;
        font-weight: 400;
        font-family: "Poppins";
      }

      .SubTotle {
        font-size: 14px;
        color: $white;
        font-weight: 400;
        font-family: "Poppins";
      }
    }
  }
}
