@import "../../../../Assets/Theme/_var.scss";

.strategies_page {
  .strategies {
    background: url("../../../../Assets/Images/strategies-bg.png") no-repeat;
    background-size: cover;
    border-radius: 29px;
    opacity: 1;
    padding: 0 11.4rem;

    @media (max-width: 575px) {
      padding: 0 2rem;
    }

    .shido_contant {
      padding: 53px 0;

      h4 {
        font-size: 3.5rem;
        color: $light-red;

        @media (max-width: 575px) {
          font-size: 2rem;
        }

        span {
          color: $white;
        }
      }

      p {
        font-size: 1.6rem;
        line-height: 2.8rem;
        color: light-blue;

        @media (max-width: 575px) {
          font-size: 1.2rem;
        }
      }
    }
  }

  .liquidity_content {
    margin-top: 3rem;
    margin-bottom: 2rem;

    h4 {
      font-size: 2.2rem;
      line-height: 3rem;
      margin-bottom: 1rem;

      @media (max-width: 575px) {
        font-size: 1.8rem;
      }
    }

    p {
      font-size: 1.4rem;
      line-height: 2.8rem;
      color: light-blue;

      @media (max-width: 575px) {
        font-size: 1.2rem;
      }
    }
  }

  .shido_earn {
    // background: $gradient-2;
    background: $darkblack;
    border-radius: 1.3rem;
    padding: 2rem 2.8rem;

    @media (max-width: 575px) {
      padding: 1.2rem 1.2rem;
    }

    p {
      font-size: 1.6rem;
      line-height: 3rem;
      color: $white;
      margin-bottom: 0;

      @media (max-width: 575px) {
        font-size: 1.4rem;
        line-height: 2rem;
        max-width: 250px;
      }
    }
  }
}

/*table css*/
.strateigies_table {
  margin-top: 25px;

  @media (max-width: 991px) {
    overflow-x: scroll;
  }

  table {
    tr {
      th {
        &:last-child {
          min-width: 0;
          text-align: right;
        }
      }
    }

    tbody {
      padding-top: 1.8rem;

      tr {
        td {
          font-size: 1.6rem !important;
          @media (max-width: 767px) {
            font-size: 1.2rem;
          }

          min-width: 140px;

          &:last-child {
            min-width: 0;
            text-align: right;
          }

          .table_data {
            span {
              width: 45px;
            }

            .table_contant {
              width: calc(100% - 45px);
              margin-left: 1rem;

              h5 {
                font-size: 1.6rem;
                color: $white;

                @media (max-width: 767px) {
                  font-size: 1.2rem;
                }
              }

              p {
                font-size: 1.4rem;
                color: $light-blue;
                margin-bottom: 0;

                @media (max-width: 575px) {
                  font-size: 1rem;
                }

                span {
                  color: $darkgrey;
                  margin-left: 0.5rem;
                  font-size: 1.4rem;
                }
              }
            }
          }

          span {
            color: $light-blue;
            margin-left: 0.5rem;
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}
