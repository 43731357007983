@import "../Assets/Theme/_var.scss";

.internet-overlay {
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ea332d25;
  color: #fff;
  margin-bottom: 20px;
  border-radius: 8px;
  text-align: center;
  padding: 0.75rem;
  p {
    font-size: 1.6rem;
    margin-bottom: 0;
  }
}
