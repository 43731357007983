@import "../../../../Assets/Theme/_var.scss";

.limit-input {
  display: flex;
  align-items: center;
  span {
    margin-top: 2px;
    margin-left: 0.75rem;
    font-weight: 500;
  }
  .form-control {
    border: 0;
    padding: 0;
    text-align: left;
    line-height: 1;

    &::placeholder {
      color: gray;
      font-weight: 500;
    }

    &:focus {
      box-shadow: none;
    }
    font-size: 1.8rem;
    font-weight: 600;
    @media (max-width: 1439px) {
      font-size: 1.6rem;
    }

    @media (max-width: 575px) {
      font-size: 1.4rem;
    }
  }
}

.LimitCard {
  // padding: 2rem 2.4rem 1.5rem;
  padding: 1rem 2.4rem;
  min-height: fit-content;
  @media (max-width: 767px) {
    padding: 1rem 1.4rem;
  }

  h5 {
    flex-shrink: 0;
    margin-right: 1rem;

    @media (max-width: 767px) {
      font-size: 1.4rem;
    }
  }

  &_head {
    margin-bottom: 1rem;
    @media (max-width: 767px) {
      margin-bottom: 0.5rem;
    }
    &_btns {
      button {
        background-color: transparent;
        border: 0;
        padding: 0;
        &:first-child {
          margin-right: 1rem;
          @media (max-width: 575px) {
            margin-right: 0.5rem;
          }
        }
        svg {
          width: 1.8rem;
          height: 1.8rem;

          @media (max-width: 575px) {
            width: 1.4rem;
            height: 1.4rem;
          }
          path {
            fill: $skyblue;
          }
        }
      }
    }
  }

  &_price {
    &_inputs {
      display: flex;
      align-items: center;
      span {
        margin-top: 2px;
        margin-left: 0.75rem;
        font-weight: 500;
      }
      .form-control {
        border: 0;
        padding: 0;
        text-align: right;
        line-height: 1;

        &:focus {
          box-shadow: none;
        }
        font-size: 1.8rem;
        font-weight: 600;
        @media (max-width: 1439px) {
          font-size: 1.6rem;
        }

        @media (max-width: 575px) {
          font-size: 1.4rem;
        }
      }
    }
  }

  &_amount {
    color: $gray;
    font-weight: 400;
    flex-wrap: wrap;
    small {
      @media (max-width: 1679px) {
        font-size: 1.3rem;
      }
      @media (max-width: 575px) {
        font-size: 1rem;
      }
    }

    span {
      img {
        width: 1.4rem;
        height: 1.4rem;
        @media (max-width: 575px) {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }

  &_time {
    @media (max-width: 767px) {
      padding: 0.75rem 0;
    }
    h6 {
      // font-size: 1.2rem;
      font-size: 1rem;
      margin-bottom: 1.8rem;
      text-align: right;
      white-space: nowrap;
      @media (max-width: 767px) {
        margin-bottom: 0;
      }
    }

    &_tooltip {
      margin-left: 0.75rem;
      flex-shrink: 0;
      img {
        width: 1.4rem;
        height: 1.4rem;
        @media (max-width: 575px) {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
  // h6 {
  //   font-size: 1.1rem;
  //   font-weight: 500;
  //   color: $white;
  //   // margin-bottom: 1.8rem;
  //   cursor: pointer;
  //   white-space: nowrap;
  // }

  // .swap_from_content {
  //   h6 {
  //     // text-transform: uppercase;
  //     img {
  //       width: 1.3rem;
  //       margin-left: 0.4rem;
  //       @media (max-width: 1430px) {
  //         width: 1rem;
  //       }
  //     }
  //   }
  //   .dropdown {
  //     .dropdown-toggle {
  //       font-size: 1.4rem;
  //       text-transform: uppercase;
  //       font-weight: 500;
  //     }
  //   }
  // }
}
