@import "../../../../Assets/Theme/_var.scss";

.explore-card {
  border-radius: 20px;
  background: linear-gradient(267deg, #4ca8f8 14.16%, #2b66f6 76.6%);
  height: 253px;
  overflow: hidden;

  @media (max-width: 1475px) {
    height: auto;
  }

  .ant-card-body {
    padding: 0;
  }

  .img-container {
    display: flex;
    justify-content: end;
    // padding-right: 32px;
    height: 362px;
  }

  .text-section {
    padding: 50px 0px 50px 50px;
    // margin: auto 0;

    .header {
      text-align: left;
      .heading {
        font-size: 56px !important;
      }
    }

    .text {
      text-align: left;
      margin-top: 36px;
    }

    .btn-col {
      text-align: left;
      margin-top: 20px;
      input {
        background-color: #fff !important;
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1799px) {
  .explore-card {
    .heading {
      font-size: 36px !important;
    }

    .img-container {
      display: flex;
      justify-content: flex-end;
      padding-right: unset;
    }

    .text-section {
      padding: 50px 0px 50px 50px;
      .header {
        .heading {
          font-size: 46px !important;
        }
      }
      .text {
        // margin-top: 24px;
      }
      .btn-col {
        // margin-top: 36px;
        .input {
          width: 100% !important;
        }
        input {
          width: 100% !important;
        }
      }
    }
  }
}

@media (min-width: 993px) and (max-width: 1199px) {
  .explore-card {
    .heading {
      font-size: 36px !important;
    }

    .img-container {
      display: flex;
      justify-content: flex-end;
      height: auto;
      padding-right: unset;
    }

    .text-section {
      padding: 50px 0px 50px 50px;
      .header {
        .heading {
          font-size: 36px !important;
        }
      }
      .text {
        // margin-top: 24px;
      }
      .btn-col {
        // margin-top: 36px;
        .input {
          width: 100% !important;
        }
        input {
          width: 100% !important;
        }
      }
    }
  }
}

@media (min-width: 601px) and (max-width: 992px) {
  .explore-card {
    .heading {
      font-size: 36px !important;
    }

    .img-container {
      display: flex;
      justify-content: flex-end;
      height: auto;
      padding-right: unset;
    }

    .text-section {
      padding: 36px 0px 36px 36px;
      .header {
        .heading {
          font-size: 36px !important;
        }
      }
      .text {
        // margin-top: 24px;
      }
      .btn-col {
        // margin-top: 36px;
        .input {
          width: 100% !important;
        }
        input {
          width: 100% !important;
        }
      }
    }
  }
}

@media (min-width: 396px) and (max-width: 600px) {
  .explore-card {
    .heading {
      font-size: 36px !important;
    }

    .img-container {
      justify-content: flex-end;
      height: auto;
      padding-left: 20px;
      padding-right: unset;
    }

    .text-section {
      margin-top: 36px;
      padding: 16px;
      .header {
        .heading {
          font-size: 36px !important;
        }
      }
      .text {
        margin-top: 24px;
      }
      .btn-col {
        margin-top: 36px;
        .input {
          width: 100% !important;
        }
        input {
          width: 100% !important;
        }
      }
    }
  }
}

@media (min-width: 200px) and (max-width: 395px) {
  .explore-card {
    .heading {
      font-size: 36px !important;
    }

    .img-container {
      height: auto;
      padding-left: 20px;
      padding-right: unset;
      justify-content: flex-end;
    }

    .text-section {
      margin-top: 36px;
      padding: 16px;
      .header {
        .heading {
          font-size: 36px !important;
        }
      }
      .text {
        margin-top: 24px;
      }
      .btn-col {
        margin-top: 36px;
        .input {
          width: 100% !important;
        }
        input {
          width: 100% !important;
        }
      }
    }
  }
}
