@import "../../../../Assets/Theme/_var.scss";

.shido_token {
  .token_tab_content {
    @media (max-width: 575px) {
      padding: 2rem 2.5rem;
    }
    .token_box {
      background-color: $darkblue;
      border-radius: 12px;
      padding: 2.8rem 2.4rem;

      @media (max-width: 575px) {
        padding: 1.8rem 1.4rem;
      }

      .token_amount {
        .amount_content {
          align-items: center;
          .token_data {
            margin-left: 1rem;

            h6 {
              font-size: 1.2rem;
              color: $light-gray;
            }

            h5 {
              font-size: 1.8rem;
              @media (max-width: 425px) {
                font-size: 1.2rem;
              }
            }
          }
        }

        .amount_value {
          flex: 0 0 50%;
          position: relative;
          .form-control {
            font-size: 2rem;
            font-weight: 500;
            color: $white;
            border: 0;

            @media (max-width: 425px) {
              font-size: 1.6rem;
            }
            &::placeholder {
              color: $white;
            }
            &:focus {
              box-shadow: unset;
            }
          }

          &__conversion {
            font-size: 1.2rem;
            color: $light-gray;
            margin-bottom: 0;
            position: absolute;
            right: 0;
            bottom: -1.5rem;
            @media (max-width: 575px) {
              bottom: -1rem;
            }
          }
        }
      }
    }

    .stake_value {
      margin-top: 2.1rem;

      .stake_content {
        background-color: $baseColor-7;
        border-radius: 21px;
        width: 23%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem 0;
        font-size: 1.8rem;
        @media (max-width: 425px) {
          font-size: 1.2rem;
        }
        &:hover {
          background-color: $primary-clr;
        }
      }
    }

    .stake_swap_card {
      margin-top: 2rem;

      .stake_tite {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          font-size: 1.4rem;

          @media (max-width: 425px) {
            font-size: 1.2rem;
          }

          span {
            img {
              width: 11px;
              height: 11px;
            }

            margin-left: 1rem;
          }
        }

        h6 {
          font-size: 1.4rem;

          @media (max-width: 425px) {
            font-size: 1.2rem;
          }

          span {
            color: $light-blue;
          }
        }
      }
    }

    .stake_fund_content {
      .fund_content {
        padding: 1.7rem 1.5rem;
        background-color: $light-red2;
        border-radius: 1.3rem;
        margin-top: 1.7rem;

        p {
          font-size: 1.6rem;
          line-height: 3rem;
          color: $light-red;
          margin-bottom: 0;
          @media (max-width: 425px) {
            font-size: 1.2rem;
          }
        }
      }

      .fixed_fee {
        font-size: 1.6rem;
        line-height: 3rem;
        padding: 0 1.5rem;
        color: $light-blue;
        margin-top: 1.2rem;
        margin-bottom: 0;

        @media (max-width: 425px) {
          font-size: 1.2rem;
        }
      }
    }
  }
}
