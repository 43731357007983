.swaps {
  padding: 24px;
  height: 60%;
  border-radius: 20px;
  border: 1px solid var(--Secondary-600, #37373c);
  background: var(--Secondary-700, #252527);
  margin-left: 20px;
  position: sticky;
  top: 10px;
  .heading {
    display: flex;
  }
  .balance {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    button {
      border-radius: 4px;
      background: var(--primary-500-default, #3b87f7);
      display: flex;
      align-items: center;
    }
  }
  .arrow {
    position: absolute;
    left: 45%;
    bottom: 200px;
    img {
    }
  }
  .swap-from {
    margin-top: 16px;
    border-radius: 8px;
    background: #37373c;
    padding: 18px 14px;
    height: 104px;
    display: flex;
    justify-content: space-between;
    .swap-from-dropdown {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .swap-from-amount {
      display: flex;
      flex-direction: column;
    }
  }

  .swap-to {
    margin-top: 30px;
    border-radius: 8px;
    background: #37373c;
    padding: 18px 14px;
    height: 104px;
    display: flex;
    justify-content: space-between;
    .swap-to-dropdown {
      display: flex;
      align-items: center;
      gap: 10px;
      .dropdown {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }

    .swap-from-amount {
      input {
        background-color: unset !important;
      }
    }
  }

  .connect-btn {
    margin-top: 36px;
    width: 100%;
    background: var(--primary-500-default, #3b87f7);
    height: 47px;
  }
}

@media (min-width: 1201px) and (max-width: 1900px) {
  .swaps {
    height: 80%;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .swaps {
    height: unset;
    margin-left: unset;
    position: relative;
    top: unset;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .swaps {
    height: unset;
    margin-left: unset;
    position: relative;
    top: unset;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .swaps {
    height: unset;
    padding: 20px;
    margin-left: unset;
    position: relative;
    top: unset;
  }
}

@media (min-width: 200px) and (max-width: 576px) {
  .swaps {
    height: unset;
    padding: 20px;
    margin-left: unset;
    position: relative;
    top: unset;
  }
}
