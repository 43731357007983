@import "../../../../../Assets/Theme/_var.scss";

.bestValue {
  color: $light-blue;
}

.matchedValue {
  color: $darkgreen;
}

.negativeValue {
  color: $redTxt;
}

.limit-order-table {
  &.Commom_Table {
    .table {
      th {
        .active {
          color: $white;
        }
      }
      tr {
        td {
          // &:first-child {
          //   width: 4rem;
          // }
          .name_Data {
            img {
              width: 3.5rem;
              height: 3.5rem;

              @media (max-width: 991px) {
                width: 2.8rem;
                height: 2.8rem;
              }
            }
            &.order-assets {
              img {
                margin-right: 0;
                &:first-child {
                  margin-right: -0.75rem;
                }
              }
            }
          }
        }
      }
    }
  }
  .cancel-btn {
    font-size: 1.4rem;
    height: 36px;
    border-width: 0.3rem;

    @media (max-width: 1679px) {
      font-size: 1.4rem;
    }
    @media (max-width: 767px) {

      font-size: 1.2rem;
      border-width: 0.2rem;
    }
  }
}

.confirmation-modal {
  .modal {
    &-body {
      p {
        font-size: 1.8rem;
        margin-bottom: 5rem;
        text-align: center;

        @media (max-width: 767px) {
          font-size: 1.5rem;
          margin-bottom: 3rem;
        }
      }
    }
  }

  &-btns {
    display: flex;
    justify-content: center;
    gap: 1.5rem;

    .button-style {
      &:first-child {
        background-color: $skyblue;
      }
    }
  }
}

.new-text-wrap {
  line-height: 1;

  small {
    font-weight: 300;
    display: block;
    margin-bottom: 0.8rem;
  }

  .tooltip_container {
    line-height: 1;
  }
}

.table-shimmer {
  .custom_shimmer {
    &:not(:last-child) {
      margin-bottom: 0.8rem;
    }
    &:nth-child(2) {
      width: 75% !important;
    }
    &:nth-child(3) {
      width: 50% !important;
    }
  }
}
