@import "../../../../Assets/Theme/_var.scss";

.card {
  border-radius: 30px;
  background: linear-gradient(91deg, #2b66f6 2.17%, #4ca8f8 98.2%);
  height: 363px;
  padding: 50px 36px 36px 36px;

  @media (max-width: 1475px) {
    height: auto;
  }

  .ant-card-body {
    padding: 0;
  }

  .img-container {
    display: flex;
    justify-content: center;

    .swappers {
      transform: rotate(-10deg);

      .arrow {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #252527;
        border: 1px solid #3b87f7;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
      }
    }
  }

  .text-section {
    margin: auto 0;

    .header {
      // font-size: 50px;
      text-align: left;
    }

    .text {
      text-align: left;
      margin-top: 36px;
    }

    .btn-col {
      text-align: left;
      margin-top: 20px;
    }
  }
}

@media (min-width: 1550px) {
  .card {
    padding: 36px;

    .heading {
      font-size: 48px !important;
    }

    .img-container {
      .swappers {
        margin-top: 30px;
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .card {
    padding: 36px;

    .heading {
      font-size: 50px !important;
    }
  }
}

@media (min-width: 993px) and (max-width: 1199px) {
  .card {
    padding: 36px;

    .heading {
      font-size: 30px !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .card {
    padding: 20px 20px;

    .heading {
      font-size: 30px !important;
    }

    .img-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .text-section {
      margin-top: 36px;

      .header {
      }

      .text {
      }

      .btn-col {
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .card {
    .heading {
      font-size: 40px !important;
    }

    .img-container {
      margin-top: 0px;
      margin-bottom: 10px;
      scale: 0.95;
    }

    .text-section {
      margin-top: 36px;

      .header {
      }

      .text {
      }

      .btn-col {
      }
    }
  }
}

@media (min-width: 350px) and (max-width: 576px) {
  .card {
    padding: 40px 20px 40px 20px !important;

    .heading {
      font-size: 40px !important;
    }

    .img-container {
      margin-top: 0px;
      margin-bottom: 10px;
      scale: 0.95;
    }

    .text-section {
      margin-top: 36px;

      .header {
      }

      .text {
      }

      .btn-col {
      }
    }
  }
}

@media (min-width: 200px) and (max-width: 350px) {
  .card {
    padding: 40px 20px 20px 20px !important;

    .heading {
      font-size: 40px !important;
    }

    .img-container {
      margin-top: 0px;
      margin-bottom: 10px;
      scale: 0.95;
    }

    .text-section {
      margin-top: 36px;

      .header {
      }

      .text {
      }

      .btn-col {
      }
    }
  }
}

@media (min-width: 200px) and (max-width: 350px) {
  .card {
    padding: 10px 10px !important;

    .heading {
      font-size: 40px !important;
    }

    .img-container {
      margin-top: 0px;
      margin-bottom: 10px;
      scale: 0.95;
    }

    .text-section {
      margin-top: 36px !important;

      .header {
      }

      .text {
      }

      .btn-col {
      }
    }
  }
}
