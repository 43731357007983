@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&family=Nunito+Sans:wght@200;300;400;500;600;700;800;900&display=swap");
@import "./Sidebar.scss";
@import "./Header.scss";
@import "./TradePage.scss";
@import "./ConnectModal.scss";

.App {
  text-align: center;
  background-color: #181819;
}

body {
  overflow-x: hidden;
  font-family: "Nunito Sans", sans-serif;
  scrollbar-width: thin;
  /* "auto" or "thin" */
  scrollbar-color: #33421f66 transparent;
  /* scroll thumb and track */
  background-color: #181819;
  width: 100%;
}

// .ant-col.ant-col-xs-24.ant-col-sm-24.ant-col-md-24.ant-col-lg-6.ant-col-xl-6.ant-col-xxl-6.css-dev-only-do-not-override-m2scbf {
//   display: flex;
// }
.shido-images .btn-col {
  margin-top: auto !important;
}

.shido-images .text {
  padding-bottom: 16px !important;
}

.slick-dots li.slick-active button:before {
  display: none;
}

.slick-dots li button:before {
  display: none;
}

.scroll-container {
  overflow-y: auto;
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* hide scrollbar for chrome, safari and opera */
.scroll-container::-webkit-scrollbar {
  display: none;
}

.hide-overflow {
  width: 100% !important;
  overflow-x: hidden !important;
}

.clickable {
  cursor: pointer;
  margin-bottom: 1px;
  margin-top: 5px;
}

.link-text {
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;

  &:hover {
    color: #0086ff;
  }
}

.ant-menu-submenu .ant-menu-submenu-inline .ant-menu-submenu-open {
  .ant-menu-submenu .ant-menu-submenu-title {
    background: lightslategray;
  }
}

.ant-select .ant-select-arrow {
  color: white;
}

.ant-select-single.ant-select-open.ant-select-selection-item {
  color: white !important;
}

a {
  color: #a3d3ff;
  font-weight: 500;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.card-no-padding {
  .ant-card-body {
    padding: 0 !important;
  }
}

.card-sm-padding {
  .ant-card-body {
    padding: 0.5em !important;
  }
}

.card-md-padding {
  .ant-card-body {
    padding: 1em !important;
  }
}

.hoverable-list-item {
  &:hover {
    background-color: #43434c;
    border-radius: 8px;
  }
}

.loading-container {
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-empty .ant-empty-image svg {
  stroke: #006bcc;

  g,
  ellipse,
  path {
    fill: #252527;
  }

  opacity: 0.7;
}

.light-empty {
  .ant-empty .ant-empty-image svg {
    stroke: #0086ff;

    g,
    ellipse,
    path {
      fill: #37373c;
    }

    opacity: 0.7;
  }
}

.ant-message .ant-message-notice .ant-message-notice-content {
  font-size: 16px;
  background-color: transparent;
  padding: 0;
  box-shadow: none;
  font-weight: 600;
  color: white;
}

.ant-message-custom-content.ant-message-success {
  padding: 12px 18px;
  border-radius: 8px;
  border: 1px solid #39ca7f;
  background-color: #37373c;
}

.ant-message-custom-content.ant-message-error {
  padding: 12px 18px;
  border-radius: 8px;
  border: 1px solid #ea332d;
  background-color: #37373c;
}

@media (min-width: 200px) and (max-width: 576px) {
  .main-layout {
    padding: 16px !important;
  }
}

.ant-menu-root {
  .ant-menu-item-selected.item-without-children {
    .ant-menu-submenu-title {
      background-color: #3b87f7 !important;
      color: white !important;

      svg {
        fill: white;
        stroke: white;

        path {
          fill: white;
          stroke: white;
        }
      }
    }
  }
}

.ant-menu-submenu-open.ant-menu-submenu-selected {
  background-color: #3b87f7 !important;
  color: white !important;
  border-bottom-right-radius: 4em 0.5em !important;
  border-bottom-left-radius: 1em 3em !important;

  svg {
    fill: white;
    stroke: white;

    path {
      fill: white;
      stroke: white;
    }
  }

  .ant-menu-sub {
    .ant-menu-item-selected {
      // border: 1px solid red;
    }
  }
}

.ant-menu-submenu-selected {
  background-color: #3b87f7 !important;
  color: white !important;

  svg {
    fill: white;
    stroke: white;

    path {
      fill: white;
      stroke: white;
    }
  }

  .ant-menu-sub {
    .ant-menu-item-selected {
      // border: 1px solid red;
    }
  }
}

.ant-carousel .slick-dots-bottom {
  bottom: -24px !important;
}

.ant-pagination .ant-pagination-item-active {
  background-color: unset;
}

.ant-table-row-indent .indent-level-0 {
  svg {
    position: absolute !important;
    left: 500px !important;
  }
}

// .ant-carousel.slick-dots li button{
//   // li {
//   //   button {
//       width: 10px !important;
//       height: 10px !important;
//       border: 1px solid red !important;
//   //   }
//   // }
// }
// .ant-carousel.slick-dots {
//   li.slick-active {
//     background: red !important;
//     opacity: 1;
//   }
// }
@media (min-width: 862px) and (max-width: 992px) {
  .slick-slide.slick-active.slick-current {
    width: 380px !important;
  }

  .slick-slide {
    // width: 531px !important;
  }
}

.transaction-numbers {
  button:hover {
    background: rgb(225, 225, 225) !important;
  }
}

.ant-table-cell {
  span {
    justify-content: unset !important;
  }
}

.OneInchSwap_Select_element {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-select-selector {
    cursor: pointer !important;
  }

  .ant-select-selection-item {
    padding-right: 0 !important;
  }

  .ant-select-arrow {
    margin: 0 !important;
    margin-right: 10px !important;
  }

  input {
    padding-left: calc(35px + (12px / 2)) !important;
    height: 21px !important;
    position: absolute !important;
    bottom: 0 !important;
    left: 0 !important;
  }
}

.t13 {
  outline: 2.2px solid #ff375f;
}


.newc-table {
  overflow-x: auto;
  max-width: calc(100vw - 32px);
  border-radius: 12px 12px 0 0;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 4px;
  }

  thead {
    border: 1px solid rgba(0, 0, 0, 0.10);
    border-radius: 12px 12px 0 0;
  }

  thead td {
    padding: 12px 24px;
    text-align: left;
  }

  tbody td {
    padding: 12px 24px;
    text-align: left;
  }

  tbody tr {
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: rgb(55, 55, 60);

    td:last-child {
      padding: 12px 20px;
    }
  }

  tr:last-child {
    border-radius: 0 0 12px 12px;
  }
}

@media screen and (max-width: 959px) {
  .newc-table {
    thead td:first-child {
      padding: 12px;
    }

    tbody td {
      padding: 12px 6px;
    }

    tbody td:first-child {
      padding: 12px;
    }

  }
}

.ant-pagination-item {
  a {
    line-height: 30px;
  }
}
.ant-pagination{
  .ant-select-dropdown{
    background-color: #252527;
  }
}