.ant-radio-wrapper .ant-radio-inner {
  background-color: #37373c;
  border-color: #d9d9d9;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #3b87f7 !important;
  background-color: #3b87f7 !important;
}

.ant-radio-wrapper .ant-radio-disabled .ant-radio-inner {
  background-color: #37373c;
  border-color: #7d7d7d;
}

.ant-checkbox-wrapper .ant-checkbox-inner {
  background-color: #37373c;
  border-color: #d9d9d9;
}

.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #3b87f7 !important;
  background-color: #3b87f7 !important;
}

.network-item {
  svg {
    width: 36px;
    height: 36px;
  }
}
