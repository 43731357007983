@import "../../../../Assets/Theme/_var.scss";

.Modal_accordion {
  .accordion-item {
    // border-bottom: 1px solid $border-clr;
    padding: 1.4rem 0;

    .accordion-header {
      .accordion-button {
        &::after {
          background-image: url(../../../../Assets/Images/down_white.svg);
          background-position: center;
        }

        span {
          &:last-child {
            // padding-right: 1rem;
            // color: $light-white;
            // font-weight: 400;
          }
        }
      }
    }

    .accordion-body {
      padding: 1rem 0;

      &.TxCostMarket_Swap {
        background-color: $darkgrey1;
        border-radius: 1rem;
        margin-top: 2rem;
        padding: 1rem 2rem;

        @media (max-width: 575px) {
          padding: 1rem;
        }

        .Txcost_Market_Card {
          border: 0;
          padding: 0;
        }
      }
    }
  }
}

.Slip_tol_Tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1rem;
  text-transform: uppercase;

  @media (max-width: 575px) {
    flex-direction: column-reverse;
    gap: 1rem;
  }

  .autoBtnActive {
    background-color: $light-blue;
    border-radius: 5rem;
    padding: 0.9rem 2rem;
    width: 10rem;
    color: $white;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    // font-style: italic;
    font-size: 1.8rem;
    font-weight: 500;

    @media (max-width: 575px) {
      font-size: 1.3rem;
      padding: 0.5rem 1rem;
    }
  }

  .activeTab {
    margin-left: 0.5rem;
    background-color: $light-blue;
    border-radius: 5rem;
    padding: 0.9rem 2rem;
    width: 8rem;
    color: $white;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    @media (max-width: 575px) {
      padding: 0.9rem 0.5rem;
      border-radius: 2rem;
      width: 5rem;
    }
  }
  .autoBtn {
    background-color: $darkblue;
    border-radius: 5rem;
    padding: 0.9rem 2rem;
    width: 10rem;
    color: $white;
    cursor: pointer;

    display: inline-block;
    text-align: center;
    // font-style: italic;
    font-size: 1.8rem;
    font-weight: 500;

    @media (max-width: 575px) {
      padding: 0.9rem 1rem;
      font-size: 1.2rem;
      width: 6rem;
    }
  }

  .Slip_tol_price,
  .Slip_tol_price_inactive {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 11.5rem);
    background-color: $baseColor;
    padding: 0.3rem;
    border-radius: 10rem;
    margin-left: 1.5rem;
    padding-left: 2rem;
    font-size: 1.6rem;
    font-weight: 400;
    color: $white;
    @media (max-width: 575px) {
      width: 100%;
      font-size: 1rem;
      margin-left: 0;
      padding-left: 1.5rem;
    }
    span {
      text-align: center;
      cursor: pointer;
    }

    .CustomValue {
      border: 1px solid $border-clr;
      color: #9ca0a7;
      padding: 0.7rem 1rem;
      width: 9.5rem;
      border-radius: 10rem;
      background-color: transparent;
      text-align: center;
      font-weight: 400;
      text-transform: uppercase;
      @media (max-width: 575px) {
        width: 7rem;
        font-size: 0.9rem;
      }
    }
  }
  .Slip_tol_price_inactive {
    padding-left: 0 !important;
  }
  .slippage_warning {
    background: $darkblack;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    color: $light-blue;
    border-radius: 1.5rem;
    font-size: 1.3rem;
    font-weight: 500;
    margin-top: 1.5rem;
    @media (max-width: 575px) {
      font-size: 1.1rem;
    }

    span {
      margin-right: 2rem;
      flex-shrink: 0;
      @media (max-width: 575px) {
        margin-right: 1rem;
      }
    }
  }
}

.Partial_Fill_list {
  .Partial_Fill_box {
    padding: 1.4rem 0;
    border-bottom: 1px solid $border-clr;
    &:last-child {
      border-bottom: 0;
    }
    span {
      img {
        margin-right: 1rem;
      }
      span {
        img {
          width: 1.1rem;
          margin-left: 0.4rem;
        }
      }
    }
  }
}
.liquid_value,
.drop_heading {
  span {
    i {
      margin-right: 0.8rem;
    }
  }
}
.drop_heading {
  margin-right: 1rem;
}

.liquid_value {
  cursor: pointer;
}

.swapSetting_Modal {
  .modal-dialog {
    max-width: 60.2rem;

    @media (max-width: 767px) {
      max-width: 100%;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
  .accordion .accordion-item .accordion-button,
  .Partial_Fill_list .Partial_Fill_box {
    @media (max-width: 575px) {
      text-transform: uppercase;
      font-size: 1.1rem;
    }
  }
}
