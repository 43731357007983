.token-graphical-view {
  border-radius: 20px;
  border: 1px solid var(--Secondary-600, #37373c);
  background: var(--Secondary-700, #252527);
  padding: 24px;
  text-align: left;
  // margin-right: 20px;s
  .ant-card-body {
    padding: 0;
  }
  .token-info {
    display: flex;
    gap: 8px;
    align-items: center;
    .wallet-address {
      height: 28px;
      padding: 4px 12px;
      border-radius: 8px;
      background: #37373c;
      display: flex;
      align-items: center;
      padding: 0px 12px;
    }
  }
  .textual-info {
    margin-top: 8px;
  }
  .percentage-info {
    display: flex;
    align-items: center;
  }
  .line-chart-container {
    width: auto;
    min-width: 490px;
    height: inherit;
  }
  .select-options {
    gap: 12px;
    width: 257px;
    height: 32px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    border-radius: 41px;
    border: 1px solid var(--Secondary-600, #37373c);
    padding: 0px 12px;
    .option {
      width: 40px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .selected-option {
      border-radius: 46px;
      background: var(--Secondary-600, #37373c);
      width: 40px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .token-graphical-view {
    margin-right: unset;
  }
}

@media (min-width: 200px) and (max-width: 767px) {
  .token-graphical-view {
    margin-right: unset;
    padding: 20px;
  }
}
