@import "../../../Assets/Theme/_var.scss";

.Liquidity {
  @media (max-width: 1490px) {
    padding: 0 3rem;
  }

  .Headings {
    .SubHeading {
      font-size: 1.4rem;
      color: $light-blue;
      line-height: 2.8rem;
      font-weight: 300;
    }
  }
}

.Voting {
  .HeadingsList {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      button {
        background: transparent;
        border: none;
        margin-right: 1rem;
      }
    }

    .activeBtnDrop {
      background: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 17rem;
      margin: 0;

      p {
        margin: 0;
        color: $white;
        font-size: 1.2rem;
      }
    }

    .BtnDrop {
      background: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 17rem;
      margin: 0;

      p {
        margin: 0;
        color: $white;
        font-size: 1.2rem;
      }

      svg {
        transform: rotate(180deg);
      }
    }

    @media (max-width: 767px) {
      flex-wrap: wrap;

      h4 {
        font-size: 1.4rem;

        button {
          margin-right: 00rem;

          svg {
            width: 2rem;
          }
        }
      }

      .BtnDrop,
      .activeBtnDrop {
        min-width: 15rem;
      }
    }
  }

  .Rishshow {
    background: #1b2e4b 0% 0% no-repeat padding-box;
    border-radius: 1.3rem;
    padding: 2rem;
    margin-top: 2rem;

    h5 {
      color: $white;
      font-size: 1.8rem;
      font-weight: 500;
    }

    p {
      margin: 0;
      font-size: 1.6rem;
      color: $light-red;
      font-weight: 400;
      line-height: 2.5rem;
    }
  }

  .RiskHide {
    display: none;
  }

  .Currentresult {
    margin-top: 2rem;
    background: $Bg-Liquidity 0% 0% no-repeat padding-box;
    border-radius: 2rem;
    padding: 2.8rem 3rem;

    @media (max-width: 767px) {
      padding: 2.5rem 1.5rem;
    }

    .BarHeader {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      h4 {
        width: 100%;

        @media (max-width: 767px) {
          font-size: 1.6rem;
        }
      }

      .BarProgress {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        .prog.progress {
          background: $baseColor 0% 0% no-repeat padding-box;
          height: 0.4rem;
          border-radius: 0.5rem;
          opacity: 1;
          width: 100%;

          .progress-bar {
            background: $BarInput 0% 0% no-repeat padding-box;
            border-radius: 0.5rem;
          }
        }

        .form-control {
          background: transparent;
          border: none;
          width: 12rem;
          padding: 1rem;
          margin-left: 1rem;
          opacity: 1;
          font-size: 2rem;
          font-weight: 500;
          font-family: "Poppins";
          color: $BarInput;

          &::placeholder {
            color: $BarInput;
          }

          &:focus {
            border: none;
            outline: none;
            box-shadow: none;
          }

          @media (max-width: 767px) {
            width: 6rem;
            padding: 0 1rem;
            margin-left: 1rem;
            opacity: 1;
            font-size: 1.4rem;
          }
        }
      }
    }

    .Current {
      display: flex;
      align-items: center;
      border-top: 1px solid transparent;
      border-image: linear-gradient(
        to right,
        transparent,
        #496b9b,
        transparent
      );
      border-image-slice: 1;
      padding: 1rem 0.5rem;
      margin-top: 1.5rem;

      @media (max-width: 767px) {
        padding: 2rem 0.5rem 0;
      }

      @media (max-width: 575px) {
        flex-wrap: wrap;
      }

      .listOfResults {
        width: 50%;
        margin-top: 2.4rem;

        // &:last-child{
        //     text-align: right;
        // }
        .Valuessub {
          font-size: 1.6rem;
          color: $white;
          font-weight: 500;
        }

        .VotesTotl {
          color: $darkgreen;
        }

        .VotesTotl2 {
          color: $light-blue;
        }

        .SubLabel {
          color: $gray;
          font-size: 1.4rem;
          font-weight: 400;
        }
      }
    }
  }

  .SubInfo {
    font-size: 1.4rem;
    color: $light-blue;
    font-weight: 300;
    margin-top: 3rem;

    span {
      font-size: 1.4rem;
      color: $white;
    }
  }

  .Trade_Simple_Card {
    //max-width: 60.2rem;
    width: 100%;
    margin: 0 auto;
    background: $cardbg;
    border-radius: 3rem;
    margin-top: 2rem;

    .Heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $cardbgheading;
      border-radius: 3rem 3rem 0 0;
      padding: 2rem 3rem;
      box-shadow: inset 0px 7px 1.2rem rgb(255 255 255 / 11%);

      .restBtn {
        background: transparent;
        border: none;
        color: $light-blue;
        font-size: 1.4rem;
        font-weight: 400;
      }
    }

    .Main_Containt {
      padding: 2rem 3rem;

      @media (max-width: 767px) {
        padding: 2rem 1.5rem;
      }

      .ValueField {
        display: flex;
        justify-content: space-around;
        position: relative;
        margin-bottom: 2rem;

        .currentValue {
          border: 1px solid #496b9b;
          border-radius: 1.9rem;
          padding: 2rem;
          margin: 0 0.5rem;
          display: flex;

          & > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .form-label {
            font-size: 1.2rem;
            color: $white;
            opacity: 0.61;
          }

          .form-control {
            background: transparent;
            border: none;
            border-radius: 0;
            font-size: 1.6rem;
            padding: 0;
            color: $white;
            font-weight: 600;

            &:focus {
              outline: none;
              background: transparent;
              border: none;
              box-shadow: none;
            }

            &::placeholder {
              color: $white;
            }
          }
        }

        .Valueaccept {
          position: absolute;
          background: $light-blue;
          border: none;
          border-radius: 5rem;
          width: 3rem;
          height: 3rem;
          top: 50%;
          transform: translateY(-50%);

          svg {
            transform: rotate(180deg);
          }
        }
      }

      .RangeSlider {
        padding: 0 1rem;

        .slideedit {
          .rc-slider-rail {
            background: $baseColor;
            height: 0.2rem;
          }

          .rc-slider-track {
            background: $light-blue;
            height: 0.3rem;
            top: 0.4rem;
          }

          .rc-slider-handle {
            margin-top: -0.6rem;
            background-color: $light-blue;
            border: 3px solid $darkblue-2;
          }
        }

        .ValueSet {
          display: flex;
          justify-content: space-between;
          margin-top: 1rem;
        }

        .InputValue {
          background-color: $darkblue;
          border-color: $darkblue;
          border-radius: 30rem;
          font-size: 1.6rem;
          padding: 0;
          color: $white;

          &:focus {
            outline: none;
            border: none;
            box-shadow: none;
          }

          .form-control {
            border: none;

            &:focus,
            &:focus-visible {
              outline: none;
              border: none;
              box-shadow: none;
            }
          }

          .input-group-text {
            background: transparent;
            border: none;
            font-size: 1.6rem;
            padding-right: 1.3rem;
            color: #a1a7b2;
          }
        }
      }
    }
  }

  .TableSet {
    .SubVotes {
      margin-bottom: 2rem;
      border-bottom: 1px solid $darkblue;
      padding: 2rem 0 1.5rem;

      .vote_value {
        margin-left: 1.4rem;
        background-color: $red-opacity;
        border-radius: 1.8rem;
        padding: 0.6rem 1.8rem;
        display: flex;
        width: 6.4rem;
        align-items: center;
        justify-content: center;

        @media (max-width: 767px) {
          width: 7.9rem;
        }

        span {
          font-size: 1.2rem;
          color: $light-red;
        }
      }
    }

    .Commom_Table {
      margin-top: 0;
    }
  }
}

.Aggregation_Page {
  .inner-cont {
    .HeadingsList {
      button {
        @media (max-width: 767px) {
          padding: 0;
          margin-top: 1rem;
        }
      }
    }
  }
}

.form-check-input:checked {
  background-color: $primary-clr;
  border-color: $primary-clr;

  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
}

// pagination style

.pagination {
  margin-top: 1.5rem;
  justify-content: end;
  .page-item {
    &:not(:last-child) {
      margin-right: 1rem;
    }

    .page-link {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      font-size: 1.4rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: $basecolor-6;
      border: 0;
    }

    &.active {
      .page-link {
        background-color: $primary-clr;
        color: $white;
      }
    }
  }
}
