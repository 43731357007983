@import "../../../../Assets/Theme/_var.scss";

.ant-col .blocks {
  display: flex;
}

.trade-comp {
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 351px;
  border-radius: 20px;
  background: var(--Secondary-600, #37373c);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);

  .heading {
    margin-top: 16px;
  }

  .text {
    margin-top: 10px;
  }

  .btn-col {
    margin-top: 24px;

    button {
      width: 100%;
    }
  }
}

@media (min-width: 1400px) and (max-width: 1919px) {
  .trade-comp {
    width: auto;
    // height: 261px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .trade-comp {
    width: auto;
    height: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .trade-comp {
    padding: 24px 12px;
    width: auto;
    height: auto;
  }
}

// @media (min-width: 1200px) and (max-width: 1280px) {
//   .trade {
//     padding: 36px;
//     width: 220px;
//     height: 320px;
//     // img {
//     //   margin-top: 48px;
//     // }
//   }
// }

// @media (min-width: 1160px) and (max-width: 1199px) {
//   .trade {
//     padding: 26px;
//     width: 237px;
//     height: 320px;
//     img {
//       margin-top: 24px;
//     }
//   }
// }

// @media (min-width: 1050px) and (max-width: 1160px) {
//   .trade {
//     padding: 26px;
//     width: 200px;
//     height: 320px;
//     img {
//       margin-top: 24px;
//     }
//   }
// }

// @media (min-width: 992px) and (max-width: 1050px) {
//   .trade {
//     padding: 26px;
//     width: 180px;
//     height: 320px;
//     img {
//       margin-top: 24px;
//     }
//   }
// }

@media (min-width: 768px) and (max-width: 992px) {
  .trade-comp {
    padding: 36px;
    width: 97%;
    height: auto;

    .img {
      // margin-top: 24px;
      margin: 0 auto;
    }
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .trade-comp {
    padding: 20px;
    width: 98%;
    height: auto;

    .img {
      margin: 0 auto;
    }
  }
}

@media (min-width: 200px) and (max-width: 576px) {
  .trade-comp {
    padding: 24px;
    width: 98%;
    height: auto;
    min-height: 260px;

    .img {
      margin: 0 auto;
    }
  }
}
