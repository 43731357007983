.SwapInputNumber.ant-input-number-sm input.ant-input-number-input {
  text-align: right;
  padding: 0;
  font-size: 18px !important;
  font-weight: 600 !important;
  width: 70%;

  &::placeholder {
    color: white;
  }

  // Text input color
  input {
    color: white;
    border: none;

    // Remove focus outline
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.SwapInputNumber {
  border-color: transparent !important;
  box-shadow: none !important;

  input {
    width: 100% !important;
  }
}
