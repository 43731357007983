// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$body: #040c33;
$baseFont: "Montserrat", sans-serif;
$baseColor: #081122;
$baseColor-2: #1e3453;
$baseColor-3: #04132a;
$baseColor-4: #1c304f;
$basecolor-5: rgb(36 40 72);
$basecolor-6: rgb(38, 58, 88, 0.48);
$baseColor-7: #13243f;
$baseColor-8: #3e78c9;
$baseColor-9: #0f1f38;
$primary-clr: #0580c1;
$light-blue: #8abbff;
$extra-light-blue: rgba(138, 187, 255, 0.2);
$white: #ffffff;
$black: #000000;
$light-white: #d5d5d5;
$redTxt: #ff7c74;
$darkgrey1: #081122;
$light-red: #f16570;
$light-red2: rgb(255, 124, 116, 0.1);
$red-opacity: rgb(255, 124, 116, 0.2);
$gray: #707988;
$light-gray: #a3a7ae;
$darkblue: #0f2039;
$darkblue2: rgba(16, 32, 57, 0.48);
$darkblack: #0e1a30;
$border-clr: #1f3953;
$border-clr-2: #273f67;
$border-clr-3: rgba(61, 120, 201, 0.2);
$border-clr-4: #466fa1;
$inner-shadow: inset 0px -3px 6px #0000003d;
// $light-blue: #8abbff;
$light-blue: #0082ff;
// $skyblue: #6da9ff;
$skyblue: #0082ff;
$skyblueDark: #1170f8;
$darkblue-2: #1e324e;
$light-blue1: #0580c1;
$darkgrey3: #1e3454;
$darkgrey: rgb(112, 121, 136);
$darkgreen: #11cabe;
$textdarklgrey: #707988;
$shimmerClr: #37373c;
$shimmerClr2: #9b9ca3;
$border-gradient: linear-gradient(
  270deg,
  #3a517233 0%,
  #ffffff33 53%,
  #3a517233 100%
);
$gradient: linear-gradient(
  45deg,
  rgba(68, 133, 224, 0.28) 0%,
  rgba(16, 32, 57, 0.48) 50%,
  rgba(68, 133, 224, 0.28) 100%
);
$gradientLight: linear-gradient(
  45deg,
  rgba(68, 133, 224, 0.1) 0%,
  rgba(16, 32, 57, 0.1) 50%,
  rgba(68, 133, 224, 0.1) 100%
);
// $gradient-2: linear-gradient(135deg,
//         rgba(3, 142, 203, 1) 0%,
//         rgba(46, 54, 138, 1) 100%);
$gradient-2: linear-gradient(135deg, #0082ff 0%, #1170f8 100%);
$gradient-3: linear-gradient(135deg, #1170f8 0%, #0082ff 100%);

// New Colour Code

$Bg-Liquidity: #163b6e;
$BarInput: #cb61fe;
$Bar-Orange: #ff9c08;
$Ocean-green: #83c259;
$Bar-Lightred: #f55f2f;
$Bar-Darkred: #f6480e;
$Bar-Lightgreen: #23c68b;
$Bar-DefaltBlue: #3181fa;
$baseColor-light: rgba(38, 58, 88, 0.5);

$border-gradient-2: linear-gradient(
  270deg,
  #39455600 0%,
  rgba(138, 187, 255, 0.2) 53%,
  #2c364400 100%
);
$cardbg: #252527;
$cardbgheading: rgba(38, 58, 88, 0.1);
$bordergradient: linear-gradient(
  146deg,
  #446da4 0%,
  #10203900 34%,
  #10203900 61%,
  #456da4 100%
);
$bordergradient1: linear-gradient(
  110deg,
  #446da4 0%,
  #10203900 36%,
  #10203900 76%,
  #456da4 100%
);
