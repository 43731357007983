@import "../../../../Assets/Theme/_var.scss";

// pool page

.pools {
  padding: 2.5rem 0;
  height: 100%;

  @media (max-width: 1599px) {
    padding: 1.5rem 0;
  }

  .pool-table {
    padding-top: 3rem;
    @media (max-width: 1599px) {
      padding-top: 2rem;
    }

    &__header {
      font-size: 1.4rem;
      font-weight: 500;
      color: #0580c1;
    }
  }
  .button-style {
    @media (max-width: 1599px) {
      font-size: 1.3rem;
      padding: 1.5rem;
    }
    @media (max-width: 767px) {
      padding: 1rem;
    }
  }
}

.commn-grid {
  display: grid;
  grid-template-columns: 16rem 3fr 1fr 1fr 1fr 24rem;
  grid-column-gap: 1.6rem;
  align-items: center;
  width: 100%;

  @media (max-width: 991px) {
    .pool-name {
      grid-area: pool-name;
    }
    .pool-govr {
      grid-area: pool-govr;
      text-align: right;
      margin-right: 4rem;
    }
    .pool-info {
      grid-area: pool-info;
    }
    .pool-liquidity {
      grid-area: pool-liquidity;
    }
    .pool-volume {
      grid-area: pool-volume;
    }
    .pool-apy {
      grid-area: pool-apy;
    }

    .pool-liquidity,
    .pool-volume,
    .pool-apy {
      display: flex;
      justify-content: space-between;
    }
    grid-gap: 0.5rem;
    grid-template-columns: auto auto auto auto auto auto;
    grid-template-rows: auto auto auto auto auto auto;
    grid-template-areas:
      "pool-name pool-name pool-name pool-name  pool-name pool-govr"
      "pool-info pool-info pool-info pool-info pool-info pool-info"
      "pool-liquidity pool-liquidity pool-liquidity pool-liquidity pool-liquidity pool-liquidity"
      "pool-volume pool-volume pool-volume pool-volume pool-volume pool-volume"
      "pool-apy pool-apy pool-apy pool-apy pool-apy pool-apy";
  }
}
