@import "../../../Assets/Theme/_var.scss";

.progress_bar {
  margin-top: 2.2rem;
  .progress {
    height: 2px;
    .progress-bar {
      // width: 100%!important;
      background-color: $darkgreen;
    }
  }
}
