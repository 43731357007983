.swapCard-tabHeadRow {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .tab-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .heading {
      font-weight: 500;
      width: auto;
      cursor: pointer;
      padding: 10px 15px;
      border-radius: 10px;
      transition: 0.2s ease-in;

      &:not(:last-child) {
        margin-right: 5px;
      }

      &.selected {
        background-color: #3b87f7;
      }

      &:hover {
        background-color: #3b87f7;
      }
    }
  }

  svg {
    cursor: pointer;
  }
}

.swapCard-content {
  .swapContent {
    .info {
      height: 50px;
      display: flex;
      justify-content: flex-end;

      .balance {
        width: 130px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .max {
          width: auto;
          padding: 5px 15px;
          border-radius: 5px;
          background-color: #3b87f7;
        }
      }
    }

    .trade {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .convertIcon {
        margin-top: -3em;
        margin-bottom: -1em;
        z-index: 10;
      }

      .tradeSetting {
        width: 100%;
        border-radius: 10px;
        padding: 10px 20px;
        background-color: #37373c;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tradeSelectComp {
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            svg {
              width: 35px;
              height: 35px;
            }
          }

          .tradeSelect {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: flex-start;

            .text {
              padding-left: 15px;
              margin-bottom: -5px;
              color: #7c7c82;
            }

            .select {
              width: 100px;
              color: white;

              svg {
                fill: white;
              }

              .ant-select-selection-item {
                color: white;
              }

              .ant-select-selector {
                padding: 0px;

                .ant-select-selection-item {
                  font-weight: 500;
                  font-size: 18px;
                }
              }
            }
          }
        }

        .currency {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;

          .crypto {
            font-weight: 500;
            font-size: 22px;
          }

          .usd {
            color: #7c7c82;
          }
        }
      }
    }

    .conversion {
      display: flex;
      justify-content: center;

      .balance {
        width: 170px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: 15px;
          font-weight: 500;
          color: #7c7c82;
        }
      }
    }
  }
}

@media only screen and (max-width: 390px) {
  .swapCard-tabHeadRow {
    .tab-head {
      .heading {
        font-size: 12px;
        padding: 5px 10px;
      }
    }
  }
}
