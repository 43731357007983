@import "../../../Assets/Theme/_var.scss";

.loader_comp_transaction {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.8);
  p {
    font-weight: 400;
  }
}
