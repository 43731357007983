.info-card {
  border-radius: 20px;
  border: 1px solid var(--Secondary-600, #37373c);
  background: var(--Secondary-700, #252527);
  height: 472px;
  padding: 24px;
  text-align: left;
  .ant-card-body {
    padding: 0;
  }
  .heading {
    text-align: left;
  }
  .info {
    margin-top: 16px;
    padding: 16px;
    height: 106px;
    border-radius: 12px;
    background: var(--Secondary-600, #37373c);

    .textual-info {
      display: flex;
      justify-content: space-between;

      .network-info {
        display: flex;
        gap: 10px;

        .network {
          height: 25px;
          padding: 0px 10px 0px 10px;
          border-radius: 8px;
          background: rgba(59, 135, 247, 0.22);
        }
      }
    }
    .number-info {
      margin-top: 9px;
      display: flex;
    }
  }
  .sub-heading {
    margin-top: 20px;
  }
  .wallet-info {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    .text {
      display: flex;
      gap: 10px;
      .information {
        display: flex;
        flex-direction: column;
      }
    }
    .number {
      display: flex;
      flex-direction: column;
    }
  }
}

@media (min-width: 1550px) {
}

@media (min-width: 1200px) and (max-width: 1599px) {
}

@media (min-width: 993px) and (max-width: 1199px) {
}

@media (min-width: 768px) and (max-width: 992px) {
}

@media (min-width: 576px) and (max-width: 767px) {
}

@media (min-width: 350px) and (max-width: 576px) {
}

@media (min-width: 200px) and (max-width: 350px) {
}

@media (min-width: 200px) and (max-width: 350px) {
  .info-card {
    border-radius: 12px;
    padding: 16px;
    .info {
      .textual-info {
        display: flex;
        justify-content: space-between;
        span {
          font-size: 12px !important;
        }
      }
      .number-info {
        span {
          font-size: 20px !important;
        }
      }
    }
    .wallet-info {
      .text {
        gap: 5px;
        .information {
          span {
            font-size: 12px !important;
          }
        }
      }
      .number {
      }
    }
  }
}
