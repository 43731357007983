@import "../../../Assets/Theme/_var.scss";

.pools-accordian {
  &.accordion {
    background: transparent;

    .accordion-item,
    .accordion-button,
    .accordion-body {
      background: transparent;
      border: 0;

      @media (max-width: 991px) {
        width: 100%;
        padding: 0 1rem;
        background: $gradient;
        border-radius: 1.2rem;
      }
    }

    .accordion-button {
      padding: 2rem 0;
      font-size: 1.6rem;
      font-weight: 500;
      text-transform: uppercase;
      color: $white;

      @media (max-width: 1599px) {
        font-size: 1.3rem;
        padding: 1rem 0;
      }

      &:focus {
        box-shadow: unset;
      }

      &:not(.collapsed) {
        box-shadow: unset;
      }

      &:not(:last-child) {
        border-bottom: 1rem solid;
        border-image-slice: 1;
        border-width: 1rem;
        border-image-source: $border-gradient;
      }

      &::after {
        position: absolute;
        right: 0;
        width: 3rem;
        height: 3rem;
        background: $extra-light-blue;
        border-radius: 50%;
        background-image: url(../../../Assets/Images/down_blue.svg);
        background-repeat: no-repeat;
        background-position: center center;

        @media (max-width: 991px) {
          top: 1rem;
        }
      }
    }

    .accordion-item {
      border-bottom: 0.1rem solid;
      border-image-slice: 1;
      border-width: 0.1rem;
      border-image-source: $border-gradient-2;

      &:last-child {
        border-bottom: 0;
      }

      @media (max-width: 991px) {
        border: 0.1px solid $border-clr;
        margin-bottom: 1rem;
      }
    }

    .accordion-body {
      color: $white;
      padding: 0;
      padding-bottom: 2rem;
    }
  }

  .pool-wrap {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;

    a {
      color: $white;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      margin-right: 1.5rem;
      position: relative;

      @media (max-width: 1599px) {
        width: 3rem;
        height: 3rem;
      }

      img {
        width: 100%;
        height: 100%;
      }

      &::after {
        position: absolute;
        content: "";
        width: 4.5rem;
        height: 4.5rem;
        border-radius: 50%;
        background-color: $extra-light-blue;

        @media (max-width: 1599px) {
          width: 4rem;
          height: 4rem;
        }
      }

      &--name {
        background-color: $light-blue;
        font-size: 2rem;
        color: $baseColor;
      }
    }
  }

  .pools-coin {
    p {
      margin-bottom: 0;
    }

    &__name {
      p {
        font-size: 1.2rem;
        color: $light-gray;
        line-height: 2.2rem;
        font-weight: 300;
      }

      a {
        font-size: 1.8rem;

        @media (max-width: 1599px) {
          font-size: 1.5rem;
        }
      }
    }

    .pool-wrap {
      &:first-child {
        margin-right: 4rem;
      }
    }
  }

  .pool-apy {
    img {
      margin-left: 0.5rem;
      width: 1.5rem;
      vertical-align: top;
    }
  }

  .pools-governance {
    padding: 0.7rem 1.5rem;
    border: 0;
    // background: $gradient-2;
    background: $darkblack;
    color: $light-blue;
    font-size: 1.2rem;
    font-weight: 400;
    border-radius: 1.5rem;
    height: 3rem;

    @media (max-width: 575px) {
      padding: 0.5rem;
      width: 3rem;
      height: 3rem;
    }

    span {
      // color: $white;
      color: $light-blue1;
    }

    img {
      margin-right: 0.8rem;
      filter: brightness(0) invert(1);

      @media (max-width: 575px) {
        margin-right: 0;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}

.pools-details {
  &__tabs {
    &.nav {
      .nav-link {
        padding: 0.8rem 2.5rem;

        @media (max-width: 1599px) {
          font-size: 1.5rem;
        }

        @media (max-width: 575px) {
          font-size: 1.3rem;
          padding: 0.5rem 1.5rem;
        }
      }
    }
  }

  &__charts {
    padding-top: 2rem;
  }

  &__right-card {
    border-radius: 2rem;
    padding: 2.5rem 2rem;
    background: rgba(38, 58, 88, 0.48);

    @media (max-width: 1599px) {
      padding: 2rem;
    }

    .details-list {
      font-size: 1.4rem;

      @media (max-width: 1599px) {
        font-size: 1.2rem;
      }

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;

        @media (max-width: 1599px) {
          margin-bottom: 1rem;
        }

        span {
          &:first-child {
            opacity: 0.6;
          }
        }

        &.total-list {
          padding-top: 3rem;

          @media (max-width: 1599px) {
            padding-top: 2rem;
          }
        }

        &.text-blue {
          color: $light-blue;
        }
      }
    }
  }
}
