@import "../../../Assets/Theme/_var.scss";

.route-card {
  .selected-route {
    &.no-middleware {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .path_line {
        margin: 0 20px;
      }
    }

    margin-top: 12px;

    .middleware-top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .asset {
      font-size: 13px;

      span {
        color: rgb(124, 124, 130);
        margin-left: 5px;
      }
    }

    .coin-box {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 13px;
      gap: 10px;

      .chain {
        font-size: 10px;
        color: rgb(124, 124, 130);
      }
    }

    .img-box {
      position: relative;

      .asset-img {
        width: 30px;
        height: 30px;
        border-radius: 30px;
      }

      .coin-img {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 15px;
        left: 15px;
        right: 0;
        bottom: -4px;
        border-radius: 20px;
        background: black;
      }
    }

    .path_line {
      position: relative;
      flex: 1;

      border-bottom: dashed 2px rgb(124, 124, 130);
      width: 100%;

      &.from::before {
        content: "";
        display: block;
        position: absolute;
        left: -12px;
        top: -4px;
        width: 10px;
        height: 10px;
        border-radius: 6px;
        background: rgb(124, 124, 130);
      }

      &.to::after {
        content: "";
        display: block;
        position: absolute;
        right: -6px;
        top: -3px;
        width: 6px;
        height: 6px;
        border: solid 2px rgb(124, 124, 130);
        border-left: none;
        border-bottom: none;
        transform: translateX(0) translateY(0) rotate(45deg) skewX(0) skewY(0)
          scaleX(1) scaleY(1);
      }
    }

    .cross-current__middleware {
      position: relative;
      min-height: 120px;
      margin: 20px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .path_line {
        border: 2px dashed rgb(124, 124, 130);
        border-top: none;
        height: 40px;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        border-radius: 0 0 10px 10px;
        z-index: 0;

        &:after {
          content: "";
          display: block;
          position: absolute;
          left: -6px;
          top: -12px;
          width: 10px;
          height: 10px;
          border-radius: 6px;
          background: rgb(124, 124, 130);
        }

        &:before {
          content: "";
          display: block;
          position: absolute;
          right: -5px;
          top: -5px;
          width: 7px;
          height: 7px;
          border: solid 2px rgb(124, 124, 130);
          border-left: none;
          border-bottom: none;
          transform: translateX(0) translateY(0) rotate(-45deg) skewX(0)
            skewY(0) scaleX(1) scaleY(1);
        }
      }

      .path_swap {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #9f9fa4;
        .amount {
          color: white;
          margin-right: 5px;
          margin-left: 5px;
        }
        .chain {
          font-size: 11px;
        }

        .icon-asset {
          width: 20px;
          height: 20px;
          border-radius: 20px;
          vertical-align: middle;
          margin-right: 5px;
        }

        &.token {
          .icon-asset {
            width: 30px;
            height: 30px;
            border-radius: 30px;
          }
        }

        .dt {
          position: relative;
          margin-right: 10px;
          padding: 5px 10px;
          background: #37373c;
          border-radius: 100px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 5px;
        }
      }
    }
  }
}
