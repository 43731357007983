@import "../../../Assets/Theme/_var.scss";

.Site_footer {
  flex-shrink: 0;
  border-top: 1px solid $border-clr;
  padding: 1rem 0;

  .footerLogo {
    img {
      max-width: 200px;
    }
    span {
      font-size: 1.7rem;
      display: inline-block;
      vertical-align: middle;

      @media (max-width: 767px) {
        font-size: 2rem;
      }
    }
  }

  .copyRight {
    margin-bottom: 0;
    font-size: 1.4rem;
  }
}
