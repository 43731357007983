@import "../../../../Assets/Theme/_var.scss";

.overview {
  .ant-card-body {
    padding: 0;
  }

  margin-top: 50px;

  .overview_card {
    padding: 60px;

    .info-cards-row1 {
      margin-top: 60px;

      .bar-chart {
        width: 560px;

        .scroll-container {
          margin-top: 10px;
          -ms-overflow-style: none;
          /* Internet Explorer 10+ */
          scrollbar-width: none;
          /* Firefox */
        }

        .scroll-container::-webkit-scrollbar {
          display: none;
          /* Safari and Chrome */
        }

        .bar-chart-container {
          width: auto;
          min-width: 490px;
          height: inherit;
        }
      }

      .staking {
        background-color: #37373c;
        border-radius: 30px;
        padding: 24px;

        .heading {
          text-align: left;
        }

        .staking-container {
          margin-top: 16px;

          .value-box {
            padding: 20px;
            height: 401px;
            border-radius: 17px;
            background-color: #a3d3fb;

            .heading {
              text-align: left !important;
            }

            .value {
              text-align: left !important;
            }
          }

          .unique-staker {
            padding: 20px;
            height: 196px;
            border-radius: 17px;
            background: #3b87f7;

            .heading {
              text-align: left !important;
            }

            .value {
              text-align: left !important;
            }
          }

          .trading-fee {
            padding: 20px;
            height: 196px;
            border-radius: 17px;
            margin-top: 10px;
            background: #3b87f7;

            .heading {
              text-align: left !important;
            }

            .value {
              text-align: left !important;
            }
          }
        }
      }

      .graph {
        background-color: #37373c;
        // height: 463px;
        border-radius: 30px;
        padding: 24px;
        min-height: 100%;

        .heading {
          text-align: left;
        }
        .info-col {
          margin-top: 24px;
          width: 100%;
          .trading-volume-info {
            background: #45454b;
            padding: 12px;
            border-radius: 12px;
            .price {
              display: flex;
              .logo-text {
                display: flex;
                align-items: center;
              }
            }
            .logo-text {
              display: flex;
              align-items: center;
            }
            // .btn-col {
            // display: flex;
            // align-items: center;
            .logo-btn {
              display: flex;
              justify-content: end;
            }
            // }
          }
        }
      }
    }

    .info-cards-row2 {
      margin-top: 30px;

      .transaction-numbers {
        border-radius: 30px;
        background: linear-gradient(91deg, #2b66f6 2.17%, #4ca8f8 98.2%);
        padding: 30px;
        height: 344px;

        .heading {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .image-container {
          margin-top: 36px;

          .image-container-row {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .arrow {
            position: absolute;
            left: 45%;
            width: 50px;
            height: 50px;
            z-index: 50;
            // margin: 0px -20px;
            background-color: #252527;
            border-radius: 50%;
            border: 1px solid #3b87f7;
            rotate: -90deg;
            box-shadow: -17px 16px 37px 0px rgba(0, 0, 0, 0.35);
            img {
              padding: 10px;
              width: 50px;
              height: 50px;
            }
          }

          .swapper-text {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
          }
        }

        .btn-col {
          margin-top: 36px;
          width: 100%;

          button {
            background-color: white;
            color: #3b87f7;
            border-radius: 8px;
            box-shadow: -2px 7px 25px 0px rgba(0, 0, 0, 0.2);
          }
        }
      }

      .reward {
        background-color: #37373c;
        border-radius: 30px;
        padding: 30px 24px;
        height: 344px;

        .curr-reward {
          // margin-top: 26px;
          text-align: initial;
          position: relative;
        }

        .custom-btn {
          height: 33px;
          width: 77px;
          color: black;
          background-color: #3b87f7;
          border-radius: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .total-reward {
          // margin-top: 26px;
          text-align: initial;
        }

        .reward-btn {
          width: 100%;
          height: 52px;
          border-radius: 15px;
          background-color: #3b87f7;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

@media (min-width: 1600px) {
  .overview {
    .overview_card {
      .info-cards-row1 {
        .staking {
          .heading {
          }

          .staking-container {
            .value-box {
              height: 401px;

              .heading {
              }

              .value {
              }
            }

            .unique-staker {
              height: 196px;

              .heading {
              }

              .value {
              }
            }

            .trading-fee {
              height: 196px;

              .heading {
                // text-align: left !important;
              }

              .value {
                // text-align: left !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1381px) and (max-width: 1599px) {
  .overview {
    .overview_card {
      padding: 60px;

      .info-cards-row1 {
        .graph {
          .heading {
          }
          .info-col {
            .trading-volume-info {
              .price {
                display: flex;
                .logo-text {
                }
              }
              .logo-text {
              }
              .btn-col {
                .logo-btn {
                }
              }
            }
          }
        }
      }

      .info-cards-row2 {
        .transaction-numbers {
          border-radius: 20px;
          padding: 30px 12px;
          height: auto;
          .arrow {
            left: 45%;
            width: 35px !important;
            height: 35px !important;
            img {
              width: 35px !important;
              height: 35px !important;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1380px) {
  .overview {
    .overview_card {
      padding: 60px;

      .info-cards-row1 {
        .graph {
          .heading {
          }
          .info-col {
            .trading-volume-info {
              .price {
                display: flex;
                flex-direction: column;
                .logo-text {
                }
              }
              .logo-text {
                align-items: flex-end;
              }
              .logo-btn {
                .button {
                  font-size: 11px;
                  padding: 6px 8px;
                }
              }
            }
          }
        }
      }

      .info-cards-row2 {
        .transaction-numbers {
          border-radius: 20px;
          padding: 30px 12px;
          height: auto;
          .arrow {
            left: 45%;
            width: 35px !important;
            height: 35px !important;
            img {
              width: 35px !important;
              height: 35px !important;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .overview {
    .overview_card {
      padding: 60px;

      .info-cards-row1 {
        margin-top: 60px;
        .scroll-container {
          height: unset !important;
        }
        .bar-chart {
          width: 457px;

          .bar-chart-container {
            min-width: 437px;
          }
        }

        .graph {
          width: unset;
        }
      }

      .info-cards-row2 {
        .transaction-numbers {
          border-radius: 20px;
          padding: 30px 24px;

          height: auto;

          .image-container {
            .arrow {
              margin: -10px auto;
              rotate: unset !important;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .overview {
    .overview_card {
      padding: 36px;

      .info-cards-row1 {
        margin-top: 60px;

        .scroll-container {
          height: unset !important;
        }

        .bar-chart {
          width: 457px;

          .bar-chart-container {
            min-width: 437px;
          }
        }
        .graph {
          .heading {
          }
          .info-col {
            .trading-volume-info {
              .price {
                display: flex;
                flex-direction: column;
                .logo-text {
                  .text {
                    font-size: 14px !important;
                  }
                }
              }
              .logo-text {
                align-items: flex-end;
                .text {
                  font-size: 14px !important;
                }
              }
              .logo-btn {
                .button {
                  font-size: 11px;
                  padding: 6px 8px;
                }
              }
            }
          }
        }
      }

      .info-cards-row1 {
        .staking {
          .staking-container {
            margin-top: 10px;

            .trading-fee {
              height: auto;
            }

            .unique-staker {
              height: auto;
            }

            .value-box {
              padding: 16px;
              height: auto;
            }
          }
        }
      }

      .info-cards-row2 {
        .transaction-numbers {
          border-radius: 20px;
          padding: 30px 24px;
          height: auto;

          .image-container {
            .arrow {
              margin: -10px auto;
              rotate: unset !important;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .overview {
    .overview_card {
      padding: 36px;

      .info-cards-row1 {
        .scroll-container {
          height: unset !important;
        }
        .staking {
          .staking-container {
            margin-top: 10px;

            .trading-fee {
              padding: 16px;
              height: auto;
            }

            .unique-staker {
              height: auto;
              padding: 16px;
            }

            .value-box {
              padding: 16px;
              height: auto;
            }
          }
        }
        .graph {
          .heading {
          }
          .info-col {
            .trading-volume-info {
              .price {
                display: flex;
                flex-direction: column;
                .logo-text {
                  .text {
                    font-size: 16px !important;
                  }
                }
              }
              .logo-text {
                align-items: flex-end;
                .text {
                  font-size: 16px !important;
                }
              }
              .logo-btn {
                .button {
                }
              }
            }
          }
        }
      }

      .info-cards-row2 {
        .transaction-numbers {
          border-radius: 20px;
          padding: 30px 24px;
          height: auto;

          .image-container {
            .arrow {
              margin: -10px auto;
              rotate: unset !important;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 450px) and (max-width: 576px) {
  .overview {
    .overview_card {
      padding: 20px;

      .heading {
        .statistics-heading {
          font-size: 30px !important;
        }
      }

      .info-cards-row1 {
        .scroll-container {
          height: unset !important;
        }
        .staking {
          .staking-container {
            margin-top: 10px;

            .trading-fee {
              padding: 16px;
              height: auto;
            }
            .unique-staker {
              padding: 16px;
              height: auto;
            }

            .value-box {
              padding: 16px;
              height: auto;
            }
          }
        }
        .graph {
          .heading {
          }
          .info-col {
            .trading-volume-info {
              .price {
                display: flex;
                flex-direction: column;
                .logo-text {
                  .text {
                    font-size: 12px !important;
                  }
                }
              }
              .logo-text {
                align-items: flex-end;
                .text {
                  font-size: 12px !important;
                }
              }
              .logo-btn {
                .button {
                  font-size: 12px;
                  // padding: 6px 12px;
                }
              }
            }
          }
        }
      }

      .info-cards-row2 {
        .transaction-numbers {
          border-radius: 20px;
          padding: 30px 24px;
          height: auto;

          .image-container {
            .arrow {
              margin: -10px auto;
              rotate: unset !important;
            }
          }
        }
      }
    }
  }

  .reward {
    .reward-btn {
      .reward-btn-text {
        font-size: 20px !important;
      }
    }
  }
}

@media (min-width: 200px) and (max-width: 449px) {
  .overview {
    .overview_card {
      padding: 20px;

      .heading {
        .statistics-heading {
          font-size: 30px !important;
        }
      }

      .info-cards-row1 {
        margin-top: 60px;

        .scroll-container {
          height: unset !important;
        }

        .bar-chart {
          width: 560px;

          .bar-chart-container {
            min-width: 540px;
          }
        }

        .staking {
          background-color: #37373c;
          border-radius: 30px;
          padding: 24px;

          .heading {
            text-align: left;
          }

          .staking-container {
            margin-top: 10px;

            .trading-fee {
              padding: 16px;
              height: auto;

              .value {
                .trading-fee-text {
                  font-size: 24px !important;
                }
              }
            }

            .unique-staker {
              padding: 16px;
              height: auto;

              .value {
                .unique-staking-text {
                  font-size: 24px !important;
                }
              }
            }

            .value-box {
              padding: 16px;
              height: auto;

              .value {
                .staking-text {
                  font-size: 24px !important;
                }
              }
            }
          }
        }
        .graph {
          .heading {
          }
          .info-col {
            .trading-volume-info {
              .price {
                display: flex;
                flex-direction: column;
                .logo-text {
                  .text {
                    font-size: 12px !important;
                  }
                }
              }
              .logo-text {
                align-items: flex-end;
                .text {
                  font-size: 12px !important;
                }
              }
              .logo-btn {
                .button {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .info-cards-row2 {
        .transaction-numbers {
          border-radius: 20px;
          padding: 30px 24px;
          height: auto;

          .image-container {
            .arrow {
              left: 45%;
              width: 35px !important;
              height: 35px !important;
              margin: -10px auto;
              rotate: unset !important;
              img {
                width: 35px !important;
                height: 35px !important;
              }
            }
          }
        }
      }
    }
  }

  .reward {
    .reward-btn {
      .reward-btn-text {
        font-size: 20px !important;
      }
    }
  }
}
