@import "../../../Assets/Theme/_var.scss";

.button-style {
  background-color: transparent;
  color: $white;
  border: 0.4rem solid $light-blue;
  border-radius: 5rem;
  // box-shadow: $inner-shadow;
  // font-size: 2.4rem;
  font-size: 2.2rem;
  line-height: 2rem;
  // font-style: italic;
  text-transform: uppercase;
  font-weight: 500;
  padding: 1.6rem;
  transition: all 0.3s ease-in-out;

  &:disabled {
    border-color: $light-blue;
    pointer-events: auto;
    cursor: not-allowed;
    opacity: 0.6;
    &:hover {
      background-color: transparent;
    }
  }

  @media (max-width: 767px) {
    font-size: 1.6rem;
    padding: 1.2rem 1.6rem;
    border-width: 0.2rem;
  }

  &:hover {
    border-color: $light-blue;
    color: $white;
    background-color: $light-blue;
  }

  &.connect-btn {
    border-width: 0.2rem;
    padding: 1rem;
    font-size: 1.2rem;
    @media (max-width: 767px) {
      padding: 0.8rem;
    }
  }

  &.gray-btn {
    background: $gray;
    color: $baseColor;
    // border: 1px solid $primary-clr;
    box-shadow: inset 0px -3px 6px #0000003d;

    &:hover {
      color: $primary-clr;
      background: transparent;
      border-color: $primary-clr;
    }
  }

  &.or-btn {
    border-width: 0.2rem;
    border-radius: 3rem;
    font-size: 1rem;
    padding: 0.55rem 1.6rem;
    cursor: pointer;
  }
}
