@import "../../../../Assets/Theme/_var.scss";

.SwapCards {
  .double_value {
    margin-top: 2.2rem;
    font-size: 1.5rem;
    color: $light-white;
    font-weight: 300;
  }
}

.YouSellBuyCard {
  .Swap_You_Cards {
    position: relative;
    .swap_from {
      width: 16rem;

      @media (max-width: 575px) {
        width: 12rem;
      }
    }

    .swap_from_price {
      width: calc(100% - 16rem);
      justify-content: flex-end;
      @media (max-width: 575px) {
        width: calc(100% - 12rem);
      }
    }
  }
  .Swap_value_change {
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: -4.2rem;
    transform: translateX(-50%);
    cursor: pointer;
    width: 5rem;
    height: 5rem;
    border-radius: 10rem;
    background-color: $skyblue;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 767px) {
      bottom: -4.2rem;
      width: 5rem;
      height: 5rem;
    }

    @media (max-width: 575px) {
      bottom: -2.2rem;
      width: 3rem;
      height: 3rem;
    }

    &:hover {
      background-color: unset;
      background-image: $gradient-3;
    }

    svg {
      width: 2.2rem;
      height: 2.2rem;
      @media (max-width: 767px) {
        width: 1.6rem;
        height: 1.6rem;
      }
      @media (max-width: 575px) {
        width: 1.3rem;
        height: 1.3rem;
      }
    }
  }

  .cross-swap_value_change {
    margin: 0.5em 0;
    cursor: pointer;
    width: 5rem;
    height: 5rem;
    border-radius: 10rem;
    background-color: $skyblue;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 767px) {
      bottom: -4.2rem;
      width: 5rem;
      height: 5rem;
    }

    @media (max-width: 575px) {
      bottom: -2.2rem;
      width: 3rem;
      height: 3rem;
    }

    &:hover {
      background-color: unset;
      background-image: $gradient-3;
    }

    svg {
      width: 2.2rem;
      height: 2.2rem;
      @media (max-width: 767px) {
        width: 1.6rem;
        height: 1.6rem;
      }
      @media (max-width: 575px) {
        width: 1.3rem;
        height: 1.3rem;
      }
    }
  }
}

.Swap_You_Cards {
  background-color: #37373c;
  border-radius: 10px;
  padding: 1.2em 1.4em;
  margin-bottom: 3.2rem;

  @media (max-width: 575px) {
    padding: 1.2rem;
    margin-bottom: 1.5rem;
  }

  // &:last-child {
  //   margin-bottom: 0;
  // }

  span {
    font-size: 1.6rem;
    font-weight: 300;
    color: $light-white;
    margin-top: -2px;

    @media (max-width: 1439px) {
      font-size: 1.4rem;
    }

    @media (max-width: 991px) {
      font-size: 1.2rem;
    }

    &.shimmer_insert {
      display: flex;
      margin-top: 0;
      justify-content: flex-end;
    }
  }

  .swap_from {
    position: relative;
    span {
      position: absolute;
      left: 5.4rem;
      line-height: 3rem;
      font-weight: 400;
      font-size: 1.4rem;

      @media (max-width: 991px) {
        left: 4.2rem;
        font-size: 1.2rem;
        line-height: 2rem;
      }
      @media (max-width: 575px) {
        left: 3.6rem;
        font-size: 1rem;
      }
    }
    .swap_from_content {
      align-items: flex-end !important;
    }
  }

  .swap_from_content {
    // margin-top: 5px;

    .height_adjust {
      height: 2.1rem;
    }
    .SwapIcon {
      width: 4.5rem;
      height: 4.5rem;
      margin-right: 1rem;
      border-radius: 4.5rem;
      @media (max-width: 991px) {
        width: 3.5rem;
        height: 3.5rem;
        margin-right: 0.75rem;
      }
      @media (max-width: 575px) {
        width: 3rem;
        height: 3rem;
      }
    }

    h5 {
      // font-size: 2rem;
      font-size: 1.8rem;
      // font-weight: 500;
      font-weight: 700;
      margin-bottom: 0;
      cursor: pointer;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      small {
        font-size: inherit;
        display: inline-block;
        max-width: 10rem;
        @media (max-width: 767px) {
          max-width: 8rem;
        }
      }

      @media (max-width: 991px) {
        font-size: 1.6rem;
      }
      @media (max-width: 575px) {
        font-size: 1.3rem;
        font-weight: 600;
      }

      svg {
        margin-left: 0.6rem;
        transform: translateY(-1px);

        @media (max-width: 575px) {
          width: 1rem;
        }
      }
    }
  }

  .user_token_balance {
    font-size: 1.4rem;
    font-weight: 500;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: -3rem;
    right: 0;

    p {
      font-size: 1.4rem;
      font-weight: 400;
      margin-bottom: 0;
      max-width: 10rem;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 0.4rem;

      @media (max-width: 575px) {
        font-size: 1.2rem;
      }
    }

    .use_max_button {
      cursor: pointer;
      background-color: $light-blue;
      color: $white;
      border-radius: 0.5rem;
      margin-left: 0.6rem;
      font-size: 1.2rem;
      font-weight: 500;
      padding: 0.1rem 0.7rem;
    }

    @media (max-width: 575px) {
      font-size: 1.2rem;
    }
  }

  .swap_from_price {
    .form-control {
      text-align: right;
      padding: 0;
      // padding-right: 0.5rem;
      font-size: 18px;
      line-height: 3rem;
      font-weight: 600;
      border: 0;

      @media (max-width: 1439px) {
        font-size: 2.4rem;
      }

      @media (max-width: 991px) {
        font-size: 2.2rem;
      }
      @media (max-width: 575px) {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2rem;
      }

      &:disabled {
        background-color: transparent;
      }

      &::placeholder {
        color: $white;
        opacity: 1;
      }

      &:focus {
        box-shadow: none;
        border: none;
      }
    }

    .swap_from_content {
      h5 {
        margin-bottom: 0.9rem;
        margin-top: 0rem;
        cursor: default;
      }
    }
  }
}

.conversionText {
  font-size: 1.8rem;
  color: #7c7c82;
  line-height: 2.6rem;
  font-weight: 500;
  margin-top: -3rem;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 575px) {
    margin-top: -1.5rem;
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
  }

  strong {
    font-weight: 500;
  }
  .tooltip_container .hover_tooltip {
    max-width: fit-content;

    @media (max-width: 575px) {
      top: -210%;
      right: 50%;
      transform: translateX(50%);
    }

    &::after {
      top: 100%;
      left: 50%;
    }
  }
}

.swap-svg {
  svg {
    height: 0.7em;
    width: 0.7em;
  }
}
