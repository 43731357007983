@import "../../../../Assets/Theme/_var.scss";

.header-swapper {
  border-radius: 28px;
  border: 2px solid var(--Primary-600, #2e6bcc);
  background: var(--Secondary-800, #181819);
  height: 141px;
  width: 310px;
  padding: 28px 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  .swapper-text {
    margin-left: 14px;
  }
}

.statistic-swapper {
  border-radius: 28px;
  border: 2px solid var(--Primary-600, #2e6bcc);
  background: var(--Secondary-800, #181819);
  height: 123px;
  width: 250px;
  padding: 36px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: -17px 16px 37px 0px rgba(0, 0, 0, 0.35);
  .swapper-text {
    margin-left: 14px;
  }
}

@media (min-width: 1401px) and (max-width: 1800px) {
  .header-swapper {
    .swapper-text {
      margin-left: 14px;
    }
  }
  .statistic-swapper {
    border-radius: 24px;
    padding: 10px;
    width: auto;
    .swapper-text {
      .swap-from {
        font-size: 16px !important;
      }
      .currency {
        font-size: 16px !important;
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .statistic-swapper {
    border-radius: 24px;
    padding: 10px;
    width: auto;
    .swapper-text {
      .swap-from {
        font-size: 16px !important;
      }
      .currency {
        font-size: 14px !important;
      }
    }
  }
}

@media (min-width: 1051px) and (max-width: 1199px) {
  .statistic-swapper {
    border-radius: 24px;
    padding: 32px 26px;
    width: auto;
    .swapper-text {
    }
  }
}

@media (min-width: 992px) and (max-width: 1050px) {
  .header-swapper {
    height: 141px;
    width: 270px;
    .swapper-text {
      margin-left: 14px;
    }
  }
  .statistic-swapper {
    border-radius: 24px;
    padding: 32px 26px;
    width: auto;
    .swapper-text {
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .header-swapper {
    .swapper-text {
    }
  }
  .statistic-swapper {
    border-radius: 24px;
    padding: 32px 26px;
    width: auto;
    .swapper-text {
    }
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .header-swapper {
    width: 387px;
    height: 178px;
    .swapper-text {
    }
  }
  .statistic-swapper {
    border-radius: 24px;
    padding: 32px 26px;
    height: 115px;
    width: auto;
  }
}

@media (min-width: 351px) and (max-width: 576px) {
  .header-swapper {
    width: 277px;
    height: 125px;
    .swapper-text {
    }
  }
  .statistic-swapper {
    border-radius: 24px;
    padding: 32px 26px;
    height: 115px;
    width: auto;
  }
}
@media (min-width: 310px) and (max-width: 350px) {
  .header-swapper {
    width: 200px;
    height: 125px;
    .swapper-text {
    }
  }

  .statistic-swapper {
    border-radius: 24px;
    padding: 32px 26px;
    height: 115px;
    width: auto;
    .swapper-text {
      .swap-from {
        font-size: 12px !important;
      }
      .currency {
        font-size: 10px !important;
      }
    }
  }
}

@media (min-width: 200px) and (max-width: 309px) {
  .header-swapper {
    width: 200px;
    height: 125px;
    .swapper-text {
    }
  }
  .statistic-swapper {
    padding: 15px 15px;
    height: 115px;
    width: auto;
    .swapper-text {
      .swap-from {
        font-size: 12px !important;
      }
      .currency {
        font-size: 10px !important;
      }
    }
  }
}
