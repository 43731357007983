.shido-volume-card {
  border-radius: 20px;
  border: 1px solid var(--Secondary-600, #37373c);
  background: var(--Secondary-700, #252527);
  // height: 362px;
  padding: 24px 0px;
  text-align: left;
  margin-left: 10px;
  .ant-card-body {
    padding: 0;
  }
  .textual-info {
    margin-left: 24px;
    display: flex;
    flex-direction: column;
  }
  .line-chart-container {
    width: auto;
    min-width: 490px;
    height: inherit;
  }
}

@media (min-width: 200px) and (max-width: 1199px) {
  .shido-volume-card {
    margin-left: unset;
  }
}
