@import "../../../../Assets/Theme/_var.scss";

// .Limit_Order_page {
//   .container {
//     .limit_row {
//       @media (max-width: 991px) {
//         flex-direction: column-reverse;
//         gap: 30px;
//       }
//     }
//   }
// }
.swap_from_content.sellOrder {
  flex-direction: row-reverse;
}

.Routing_box_list {
  .Routing_Dai {
    background-color: $baseColor-4;
    border-radius: 10rem;
    padding: 0.8rem 2rem;
    width: 37.6rem;

    @media (max-width: 1399px) {
      width: 28rem;
    }

    @media (max-width: 991px) {
      width: 30rem;
      padding: 0.5rem 1rem;
    }

    img {
      width: 44px;
      height: 44px;
    }

    p {
      font-size: 1.8rem;
      text-transform: uppercase;
      margin-bottom: 0;
      padding: 0 1rem;
    }

    .Routing_Dai_content {
      // background-color: $primary-clr;
      background-color: $skyblue;
      padding: 0.4rem 1rem;
      border-radius: 10rem;
      width: 20rem;

      p {
        margin-bottom: 0;
        font-size: 1.2rem;
        line-height: normal;
      }
    }
  }

  .first_Icon_info {
    padding-right: 10rem;
    position: relative;
    z-index: 1;

    img {
      min-width: 40px;
    }

    &::after {
      content: "";
      border: 1px dashed $border-clr;
      position: absolute;
      right: 0;
      top: 50%;
      width: 95%;
      z-index: -1;
    }

    @media (max-width: 1399px) {
      padding-right: 8rem;
    }

    @media (max-width: 767px) {
      padding-right: 4rem;
    }
  }

  .Last_Icon_info {
    padding-left: 10rem;
    position: relative;
    z-index: 1;

    img {
      min-width: 40px;
    }

    &::after {
      content: "";
      border: 1px dashed $border-clr;
      position: absolute;
      left: 0;
      top: 50%;
      width: 95%;
      z-index: -1;
    }

    @media (max-width: 1399px) {
      padding-left: 8rem;
    }

    @media (max-width: 767px) {
      padding-left: 4rem;
    }
  }
}

.Limit_Order_page {
  @media (max-width: 767px) {
    .Trade_Simple_Card .Trade_options {
      top: 0.9rem;
    }
  }
}
