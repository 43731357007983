@import "../../../Assets/Theme/_var.scss";

.staking_page {
  padding-bottom: 1.4rem;

  .container {
    &:not(.staking_banner_Container) {
      // max-width: 100rem;
    }
  }

  &_banner {
    // background: linear-gradient(135deg, #16161a 0%, #0f213b 100%);
    margin: 4rem 0;

    @media (max-width: 991px) {
      margin: 2rem 0;
    }

    &_Inner {
      position: relative;
      background-color: #040d12;
      padding: 2.7rem 1.5rem;
      border-radius: 3rem;
      min-height: 217px;

      @media (max-width: 991px) {
        min-height: fit-content;
      }

      .bannerWrap {
        display: flex;
        align-items: center;
        max-width: 86.5rem;
        margin: 0 auto;

        &__img {
          margin-right: 2.5rem;
          max-width: 13rem;

          @media (max-width: 991px) {
            margin-right: 1.5rem;
            max-width: 10rem;
          }

          @media (max-width: 767px) {
            margin-right: 0;
            max-width: 7rem;
          }
        }

        &__content {
          width: 100%;

          h2 {
            font-size: 4.4rem;
            line-height: 4rem;
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 0;

            @media (max-width: 991px) {
              font-size: 3.4rem;
              line-height: 3rem;
            }

            @media (max-width: 575px) {
              font-size: 2.4rem;
            }

            span {
              color: $light-blue;
            }
          }

          .rewardsTxt {
            text-transform: uppercase;
            font-size: 1.4rem;
            font-weight: 500;

            @media (max-width: 575px) {
              font-size: 0.8rem;
            }

            li {
              display: flex;
              align-items: center;
              justify-content: space-between;

              span {
                // font-size: 2.4rem;
                // font-weight: 500;
                color: $light-blue;
                margin-left: 1rem;
                font-size: 2.2rem;
                font-weight: 700;

                @media (max-width: 991px) {
                  font-size: 1.8rem;
                }

                @media (max-width: 575px) {
                  text-align: right;
                  font-size: 1.2rem;
                  flex: 0 0 50%;
                }

                &.apy_text {
                  color: $darkgreen;

                  &.shimmer_insert {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
              }

              @media (max-width: 575px) {
                &:not(:last-child) {
                  margin-bottom: 0.5rem;
                }
              }
            }
          }
        }
      }

      //   background-position: left;

      // .row {
      //   justify-content: flex-end;
      //   width: 100%;
      //   z-index: 1;
      // }
      // @media (max-width: 991px) {
      //   &:before {
      //     content: "";
      //     height: 100%;
      //     width: 100%;
      //     left: 0;
      //     top: 0;
      //     background-color: rgba(0, 0, 0, 0.6);
      //     position: absolute;
      //     border-radius: 3rem;
      //   }
      //   .text_light {
      //     opacity: 0.8;
      //   }
      // }
      // @media (max-width: 575px) {
      //   justify-content: center;
      // }
    }

    // p {
    //   font-size: 1.6rem;
    //   margin: 1.3rem 0;

    //   .apy_text {
    //     color: $skyblue;
    //   }
    // }

    // h3 {
    //   font-size: 2rem;
    // }
  }

  .common_tabs {
    border-radius: 3.2rem;
    padding: 0.2rem;
    border: 0.4rem solid $border-clr-4;
    position: relative;

    @media (max-width: 575px) {
      border-radius: 2rem;
      border-width: 0.2rem;
    }

    &_content {
      background: $baseColor;
      padding: 2rem 5.5rem;

      @media (max-width: 1199px) {
        padding: 2rem 4rem;
      }

      @media (max-width: 991px) {
        padding: 2rem;
      }

      @media (max-width: 575px) {
        padding: 1.5rem 1rem;
      }
    }

    &_nav {
      box-shadow: unset;
      background-color: transparent;
      padding: 0 5.5rem;
      margin-bottom: 1rem;

      @media (max-width: 1199px) {
        padding: 0 4rem;
      }

      @media (max-width: 991px) {
        padding: 0 2rem;
      }

      @media (max-width: 575px) {
        padding: 0 0.75rem;
        margin-bottom: 0;
      }

      .nav-link {
        @media (max-width: 767px) {
          font-size: 1.2rem;
        }

        @media (max-width: 575px) {
          font-size: 1rem;
          padding: 0.7rem 0.4rem;
        }

        @media (max-width: 375px) {
          font-size: 1rem;
        }
      }
    }
  }

  .timer_text {
    color: $white;
    font-size: 1.8rem;
    font-weight: 500;

    @media (max-width: 991px) {
      font-size: 1.4rem;
    }

    @media (max-width: 767px) {
      font-size: 1.2rem;
    }
  }

  .form-group {
    // .form-label {
    //   font-size: 1.4rem;
    //   color: $white;

    //   span {
    //     // opacity: 0.5;
    //     color: rgba(255, 255, 255, 0.5);
    //     // transform: translateY(-1px);
    //     display: inline-block;
    //     span {
    //       color: $white;
    //       padding-left: 0.5rem;
    //     }
    //   }
    // }

    // &-inner {
    //   &.icon-input-right {
    //     .form-control {
    //       padding-right: 16rem;
    //       border: 0;
    //     }

    //     .form-control-icon-right {
    //       &.icon-text {
    //         margin-bottom: 0;
    //         display: flex;
    //         align-items: center;

    //         span {
    //           font-size: 1.3rem;
    //           display: inline-block;
    //           max-width: 8rem;
    //           overflow: hidden;
    //           text-overflow: ellipsis;
    //         }

    //         .button-style {
    //           padding: 4px 1rem;
    //           margin-left: 1rem;

    //           @media (max-width: 991px) {
    //             font-size: 1.2rem;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }

  .staking_info {
    width: 100%;

    .action-btn {
      margin: 1.6rem 0;
      // font-size: 2.2rem;
      font-size: 2rem;
      padding: 1.2rem 2rem;
      min-width: 17rem;

      @media (max-width: 767px) {
        min-width: 12rem;
        margin: 1rem 0;
        font-size: 1.4rem;
        padding: 0.5rem 1rem;
      }

      @media (max-width: 767px) {
        font-size: 1.2rem;
      }
    }

    .form-group-inner {
      background-color: #37373c;
      border-radius: 8px;
      overflow: hidden;
      padding: 1em 2em;
      align-items: center;
      display: flex;

      @media (max-width: 767px) {
        padding: 1.5rem 1rem;
      }
      .use_max_button {
        cursor: pointer;
        background-color: $light-blue;
        color: $white;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 600;
        padding: 0.3rem 1rem;
        margin-right: 0;
      }

      .form-control {
        padding: 4px 12px;
        //width: 50%;
        background-color: #4f4f53;
        border-radius: 6px;
        border: 0;
        // font-size: 3.2rem;
        // font-weight: 500;
        font-size: 20px;
        font-weight: 700;
        color: $white;

        &::placeholder {
          color: $white;
        }

        &:focus {
          box-shadow: unset;
        }

        &:disabled {
          background-color: transparent;
        }
      }

      &__left,
      &__right {
        display: flex;
        align-items: center;
        text-align: left;
      }

      &__left {
        position: relative;
        top: -3px;

        @media (max-width: 767px) {
          top: 0;
        }

        .icon {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 4.4rem;
          height: 4.4rem;
          margin-right: 1rem;
          border-radius: 50%;
          background-color: #273c57;
          flex-shrink: 0;

          @media (max-width: 767px) {
            margin-right: 0.7rem;
          }

          img {
            width: 2.8rem;
          }
        }

        .text {
          // font-size: 2rem;
          line-height: 2rem;
          // font-weight: 500;
          font-size: 1.8rem;
          font-weight: 700;

          small {
            white-space: nowrap;
            font-size: 1.2rem;
            line-height: 1.2rem;
            margin-bottom: 0.5rem;
            font-weight: 500;
            color: #a3a7ae;
          }
        }
      }

      &__right {
        span {
          // font-size: 2.4rem;
          // font-weight: 500;
          font-size: 2rem;
          font-weight: 700;
          margin-right: 2.2rem;

          @media (max-width: 767px) {
            font-size: 1.2rem;
            margin-right: 1rem;
          }
        }

        .button-style {
          border: 0.1rem solid $light-blue;
          background-color: $light-blue;
          padding: 1rem 2.4rem;
          font-size: 1.8rem;
          font-weight: 500;
          font-style: normal;
          transition: all linear 0.25s;

          @media (max-width: 767px) {
            font-size: 1rem;
            padding: 0.2rem 0.7rem;
          }

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }

    &_text {
      padding-top: 1.5rem;

      ul {
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            color: $white;
            text-transform: uppercase;
            font-size: 1.8rem;
            font-weight: 500;

            @media (max-width: 767px) {
              font-size: 1.4rem;
            }

            @media (max-width: 575px) {
              font-size: 1rem;
            }
          }

          h4 {
            font-size: 2rem;
            font-weight: 500;
            margin-bottom: 0;

            @media (max-width: 767px) {
              font-size: 1.6rem;
            }

            @media (max-width: 575px) {
              font-size: 1.3rem;
            }
          }

          &:not(:last-child) {
            margin-bottom: 0.9rem;

            @media (max-width: 767px) {
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }
  }

  .staking_reward_info {
    background-color: $baseColor;
    border-radius: 3rem;
    margin-top: 3rem;
    padding: 2.4rem 5.5rem;
    border: 0.4rem solid $border-clr-4;

    @media (max-width: 1199px) {
      padding: 2rem 4rem;
    }

    @media (max-width: 991px) {
      margin-top: 2rem;
      padding: 2rem;
    }

    @media (max-width: 575px) {
      padding: 1.5rem 1rem;
      border-radius: 2rem;
      border-width: 0.2rem;
    }

    @media (max-width: 575px) {
      .button-style {
        width: 100%;
      }
    }

    h3 {
      // font-size: 2.4rem;
      // font-weight: 500;
      font-size: 2.2rem;
      font-weight: 700;
      margin-bottom: 1.1rem;
      text-transform: uppercase;

      @media (max-width: 991px) {
        font-size: 1.8rem;
      }

      @media (max-width: 575px) {
        font-size: 1.2rem;
      }
    }
  }
}

// migration tabs style

.staking-migration {
  .info_icon {
    position: absolute;
    top: 1.7rem;
    right: 3.2rem;

    @media (max-width: 575px) {
      top: 0.5rem;
      right: 1rem;
    }

    span {
      svg {
        transform: rotate(180deg);
        width: 2.2rem;
        height: 2.2rem;
        cursor: pointer;

        @media (max-width: 767px) {
          width: 1.6rem;
          height: 1.6rem;
        }

        @media (max-width: 575px) {
          width: 1.2rem;
          height: 1.2rem;
        }
      }
    }

    .info_tooltip {
      position: absolute;
      top: -1rem;
      right: calc(100% + 1.2rem);
      width: max-content;
      background-color: $baseColor;
      z-index: 100;
      box-shadow: 0 0 2rem 0.2rem rgba($white, 0.1);
      padding: 1rem 1.5rem;
      border-radius: 1rem;
      max-width: 34rem;
      visibility: hidden;
      opacity: 0;
      transition: 300ms ease-in-out;
      transform: translateX(10px);

      &::after {
        content: "";
        width: 1.4rem;
        height: 1.4rem;
        position: absolute;
        top: 1.7rem;
        left: 100%;
        background-color: $baseColor;
        transform: translateX(-50%) rotate(45deg);
      }

      p {
        font-size: 1.4rem;
        margin-bottom: 0;
      }

      @media (max-width: 767px) {
        max-width: 24rem;

        p {
          font-size: 1.2rem;
        }
      }
    }

    &:hover {
      .info_tooltip {
        visibility: visible;
        opacity: 1;
        transform: unset;
      }
    }
  }

  &_group {
    display: flex;
    align-items: center;

    > div {
      @media (max-width: 767px) {
        width: 100%;
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .form-group-inner {
      @media (max-width: 767px) {
        width: 100%;
      }
    }

    &_plus {
      font-size: 4.8rem;
      line-height: 1;
      font-weight: 400;
      margin: 0 1.5rem;

      @media (max-width: 991px) {
        font-size: 4rem;
      }

      @media (max-width: 767px) {
        font-size: 3rem;
        margin: 1rem 0;
      }

      &--arrow {
        top: -42px;
        position: relative;

        img {
          width: 4.5rem;
        }

        @media (max-width: 767px) {
          top: -12px;

          img {
            width: 2.5rem;
            transform: rotate(90deg);
          }
        }

        @media (max-width: 575px) {
          top: -12px;

          img {
            width: 1.8rem;
          }
        }
      }
    }
  }

  &_tabs {
    &_nav {
      &.nav {
        padding: 2rem;
        justify-content: center;

        @media (max-width: 991px) {
          padding: 1rem;
        }
      }

      .nav-link {
        font-size: 2rem;
        margin: 0 1rem;

        &::after {
          width: 100%;
        }
      }
    }

    &_content {
      &.tab-content {
        padding: 2rem 0;

        @media (max-width: 991px) {
          padding: 1rem 0;
        }
      }
    }

    &.common_tabs {
      border: 0;
    }
  }

  .action-btn-wrap {
    position: relative;
    padding: 1.6rem 0;

    .action-btn {
      margin: 0;
    }

    .info_icon {
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      // position: static;
    }
  }
}

.note-info {
  // right: 6rem;
  top: -3rem;
  position: absolute;
  color: $darkgreen;
  // @media (max-width: 1199px) {
  //   right: 5.5rem;

  // }

  @media (max-width: 991px) {
    // right: 0;
    top: -2rem;

    font-size: 1.2rem;
  }

  @media (max-width: 767px) {
    position: static;
    margin-bottom: 0.5rem;
  }
}
