@import "../../../../Assets/Theme/_var.scss";

.review_order_modal {
  .row {
    margin: 0 -10px;

    > div {
      padding: 0 10px;
    }
  }
  h5 {
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  .time-group {
    border-radius: 1.2rem;
    padding: 1.5rem 1rem;
    background-color: $baseColor;
    display: flex;
    align-items: center;

    .form-control {
      padding: 0.5rem 1rem;
      padding-left: 0;
      border: 0;
      font-weight: 600;

      &:focus {
        box-shadow: none;
      }
    }
    span {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1.3rem;
      color: $textdarklgrey;

      @media (max-width: 767px) {
        font-size: 1rem;
      }
    }
  }
}

.confirm {
  &_token {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // gap: 1rem;

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      padding: 1.8rem 2rem;
      background-color: $baseColor;
      border-radius: 1.2rem;

      @media (max-width: 767px) {
        padding: 1.2rem 1.4rem;
      }

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }

    &_title {
      font-size: 1.2rem;
      color: $light-gray;
      margin-bottom: 0.7rem;
    }
    &_value {
      display: flex;
      align-items: center;
      color: $white;
      font-weight: 600;
      img {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 0.5rem;
      }
    }
  }
  &_list {
    padding: 3rem 0;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      color: $light-gray;
      font-size: 1.4rem;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      span {
        &:last-child {
          color: $white;
          font-weight: 600;

          .dropdown-toggle {
            font-size: inherit;
            font-weight: inherit;
          }
        }
      }
    }
  }
}
