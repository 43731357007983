.transaction-card {
  border-radius: 20px;
  border: 1px solid var(--Secondary-600, #37373c);
  background: var(--Secondary-700, #252527);
  padding: 24px;
  text-align: left;
  .spinner {
    display: flex;
    justify-content: center;
  }

  .ant-card-body {
    padding: 0;
  }

  .heading-row {
    display: flex;
    gap: 36px;
    align-items: center;
  }

  .transaction-table {
    .for {
      display: flex;
      gap: 12px;
      align-items: center;
      div {
        display: flex;
        align-items: center;
        .text {
          margin-left: 5px;
        }
      }
    }

    .ant-table-wrapper .ant-table-tbody > tr > td {
      border-bottom: none !important;
    }
    .volume {
      border-bottom-right-radius: 12px;
    }

    .ant-table-tbody {
    }
  }
}

@media (min-width: 200px) and (max-width: 768px) {
  .transaction-card {
    padding: 20px;
  }
}
