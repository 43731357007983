@import "../../../../Assets/Theme/_var.scss";

.Select_Token_Box {
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 3rem;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background-color: $darkblue;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $light-white;
  }

  .Select_Token_List {
    padding: 1rem;
    border-radius: 0.6rem;
    cursor: pointer;

    img {
      height: 44px;
    }
    .Select_Token_name {
      margin-left: 1rem;

      h6 {
        margin-bottom: 0.2rem;
      }

      span {
        font-size: 1.4rem;
        color: $light-white;
      }
    }

    &:hover {
      background-color: $extra-light-blue;
    }

    &--disabled {
      cursor: not-allowed;
      background-color: $extra-light-blue;
      opacity: 0.5;
    }
  }
}
