@import "../../../../Assets/Theme/_var.scss";

.Commom_Table {
  .table {
    tr {
      td {
        .name_Data {
          img {
            margin-right: 1rem;
            width: 45px;
            height: 45px;
            border-radius: 10rem;
            object-fit: cover;

            @media (max-width: 767px) {
              margin-right: 0.7rem;
              width: 35px;
              height: 35px;
            }
          }

          .primaryClr {
            background: #8abbff;
            box-shadow: 0px 0px 10px #8abbff;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 20px;
            margin: 0 15px 0 10px;
            color: #102039;
          }

          .SeconeryClr {
            background: #e36e78;
            box-shadow: 0px 0px 10px #e36e78;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 20px;
            margin: 0 15px 0 10px;
            color: #102039;
          }
        }

        .decreasevoter {
          font-size: 18px;
          font-weight: 500;
          color: #ff7c74;
          margin-right: 10px;
        }

        .increasevoter {
          font-size: 18px;
          font-weight: 500;
          color: #8abbff;
        }
      }
    }
  }
}
