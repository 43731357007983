.header-body {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    width: 100%;
    height: auto;

    img {
      height: 48px;
    }
  }

  .header-right {
    svg {
      margin-left: 10px;
      cursor: pointer;
    }
  }
}

.text-scroller {
  padding: 8px;

  .success {
    color: white;
    background-color: #39ca7f33;
    border-radius: 14px;
    padding: 0 12px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .error {
    background-color: #ea332d33;
    border-radius: 14px;
    padding: 0 12px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.TextScroller {
  position: relative;

  &::after {
    content: "";
    height: 100%;
    width: 70px;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(270deg, rgba(24, 24, 25, 0) 0%, #181819 100%);
    z-index: 2;
    pointer-events: none;
  }

  &::before {
    content: "";
    height: 100%;
    width: 70px;
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(90deg, rgba(24, 24, 25, 0) 0%, #181819 100%);
    z-index: 2;
    pointer-events: none;
  }
}

@media (max-width: 600px) {
  .TextScroller {
    &::after {
      content: "";
      width: 30px !important;
    }

    &::before {
      content: "";
      width: 30px !important;
    }
  }
}
