@import "../../../Assets/Theme/_var.scss";

.limit_row {
  // @media (max-width: 1199px) {
  //   flex-direction: column-reverse;
  //   gap: 30px;
  // }

  .TradingView_Widget {
    height: 510px;
    @media (max-width: 991px) {
      height: 400px;
    }
    .tv_chart_container {
      height: 100%;
      width: 100%;
      border-radius: 20px;
      iframe#tradingview_245e5 {
        border-radius: 20px;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.low_input_error {
  color: red;
  margin-top: -1rem;
  margin-bottom: 2rem;
}
.use_connected_address {
  color: rgb(33, 105, 230);
  text-decoration: underline;
  cursor: pointer;
  margin-top: -1rem;
  margin-bottom: 2rem;
}

.p2p_deal_page {
  .container {
    max-width: 1310px;
  }

  .P2PTrade_Cards {
    max-width: 100%;
  }
}

.cross_chain_page {
  @media (max-width: 575px) {
    .user_token_balance {
      top: -7rem;
    }

    .Trade_Tabs_content {
      padding-top: 6rem;
    }
  }

  .YouSellBuyCard {
    .Swap_value_change {
      bottom: -3.5rem;
      width: 3rem;
      height: 3rem;
      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }

    .to-select-chain {
      @media (max-width: 575px) {
        margin-top: 4rem;
      }
    }
  }

  .receiver-address {
    margin-top: -1rem;
    margin-bottom: 1.5rem;
    p {
      font-size: 1.4rem;
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 0.5rem;
      @media (max-width: 575px) {
        font-size: 1.2rem;
      }
    }
    &__input {
      border-radius: 1rem;
      padding: 1rem;
      background-color: rgba($baseColor, 0.5);

      input {
        flex: 1 0;
        background-color: transparent;
        color: $white;
        border: 0;

        &:focus,
        &:disabled {
          outline: 0;
        }
      }
      button {
        margin-left: 1rem;
        background-color: transparent;
        border: 0;
        svg path {
          transition: all linear 0.25s;
        }
        &:hover {
          svg path {
            fill: $white;
            transition: all linear 0.25s;
          }
        }
      }

      @media (max-width: 575px) {
        font-size: 1rem;
      }
    }
  }

  .select-chain {
    margin-left: 0;

    .button-style {
      width: 100%;
    }
  }
}
