@import "../../../Assets/Theme/_var.scss";

.tooltip > div.tooltip-inner {
  background-color: $border-clr-2 !important;
  color: $white !important;
  font-size: 1.2rem;
  text-align: left;
  padding: 1.4rem 1.6rem;
  border-radius: 1rem;
}

.tooltip.show {
  opacity: 1 !important;
}

.tooltip > .tooltip-arrow::before {
  border-top-color: $border-clr-2 !important;
  color: $border-clr-2 !important;
}
