@import "../../../Assets/Theme/_var.scss";

.connect_wallet {
  .modal-content {
    border: 1px solid $border-clr;
    border-radius: 20px;
    background: $gradient;
    text-align: center;

    .modal-header {
      .btn-close {
        // filter: invert(1);

        &:focus {
          box-shadow: unset;
        }
      }

      .modal-title {
        color: $light-blue !important;
      }
    }

    .modal-body {
      .modal_list {
        padding: unset;

        h6 {
          text-align: left;
          opacity: 0.5;
          margin-bottom: 2rem;
        }

        .Choose_Network {
          margin-bottom: 0rem;
        }

        &__item {
          cursor: pointer;
          border-bottom: 1px solid $border-clr;
          justify-content: space-between;
          padding: 1.5rem 0rem;
          transition: all linear 0.2s;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:first-child {
            margin-top: unset;
          }

          &:last-child {
            border-bottom: 0;
          }

          &:hover {
            border-color: $light-blue;
          }

          .choose_Icon_Name {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 1.5rem;

            p {
              margin-bottom: 0;
              padding-left: 1rem;
            }
          }

          @media (max-width: 480px) {
            padding: 0.5rem 2rem;
            margin-top: 1rem;
            border-radius: 0;
          }

          p {
            margin: 0;
          }

          &__icon {
            img {
              width: 3.5rem;
              height: 3.5rem;
              object-fit: cover;
              box-shadow: 0px 0px 10px 0px #002d5c;
              border-radius: 10rem;

              // @media (max-width: 767px) {
              //   width: 4rem;
              //   height: 4rem;
              //   ;
              // }
            }
          }

          .ratioBtn {
            width: 16px;
            height: 16px;
            border: 1px solid $primary-clr;
            border-radius: 10rem;
            position: relative;
            display: inline-block;
          }

          &:hover {
            .ratioBtn::after {
              width: 10px;
              height: 10px;
              content: "";
              position: absolute;
              left: 50%;
              top: 50%;
              background-color: $light-blue;
              border-radius: 10rem;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }
}

.wallet_checkOut {
  margin-top: 2rem;
  text-align: left;

  label {
    font-size: 1.2rem;

    a {
      color: $light-blue;
    }
  }
}

.GrayBtn {
  opacity: 0.4;
}
