@import "../../../Assets/Theme/_var.scss";

.custom_shimmer {
  background-color: $shimmerClr;
  border-radius: 8px;
  animation: shimmer 2s infinite ease-out;
}

.light_shimmer {
  background-color: $shimmerClr2;
  border-radius: 8px;
  animation: shimmer 2s infinite ease-out;
}

@keyframes shimmer {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
