.staking-card {
  border-radius: 20px;
  border: 1px solid var(--Secondary-600, #37373c);
  background: var(--Secondary-700, #252527);
  height: 472px;
  padding: 24px;
  text-align: left;

  .spinner {
    display: flex;
    justify-content: center;
  }

  .ant-card-body {
    padding: 0;
  }

  .heading-row {
    display: flex;
    justify-content: space-between;
  }

  .staking-table {
    .amount {
      border-bottom-left-radius: 12px;
    }

    .type {
      border-bottom-right-radius: 12px;
      display: flex;
      justify-content: flex-end;
    }

    margin-top: 16px;

    .ant-table-tbody {
      .amount {
        color: white;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
      }
    }

    .expandable-row {
      .heading {
        border-radius: 12px;
        background: rgba(55, 55, 60, 0.3);
        height: 39px;
        display: flex;
        padding: 0px 12px;
        align-items: center;
      }

      .transaction-hash {
        margin-top: 14px;
      }
    }
  }
}

@media (min-width: 1550px) {
}

@media (min-width: 1200px) and (max-width: 1599px) {
}

@media (min-width: 993px) and (max-width: 1199px) {
}

@media (min-width: 768px) and (max-width: 992px) {
}

@media (min-width: 576px) and (max-width: 767px) {
  .staking-card {
    height: unset;

    .ant-card-body {
    }

    .heading-row {
    }

    .staking-table {
      .amount {
        border-bottom-left-radius: unset;
      }

      .type {
        border-bottom-right-radius: unset;
      }

      .expandable-row {
        .heading {
        }

        .transaction-hash {
        }
      }
    }
  }
}

.txhash:hover span {
  text-decoration: underline !important;
}

@media (min-width: 200px) and (max-width: 576px) {
  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 16px 12px;
  }

  .ant-table-wrapper .ant-table-thead > tr > th {
    padding: 16px 12px;
  }

  .staking-card {
    height: unset;
    padding: 20px !important;

    .ant-card-body {
    }

    .heading-row {
    }

    .staking-table {
      .amount {
        border-bottom-left-radius: unset;
      }

      .type {
        border-bottom-right-radius: unset;
      }

      .expandable-row {
        .heading {
        }

        .transaction-hash {
        }
      }
    }
  }
}
