@import "../../../../Assets/Theme/_var.scss";

.shidoturbo {
  .shidoturbo_main {
    .shido {
      .shido_left {
        .right_arrow {
          margin-right: 2.3rem;
          cursor: pointer;
          @media (max-width: 575px) {
            margin-right: 0;
          }
        }
        h4 {
          font-size: 2.2rem;
          margin-left: 2rem;
          margin-bottom: 0;
          @media (max-width: 575px) {
            font-size: 1.8rem;
            margin-left: 0;
          }
          @media (max-width: 374px) {
            font-size: 1.6rem;
            margin-left: 0;
          }
        }
      }

      .shido_right {
        p {
          font-size: 1.2rem;
          margin-bottom: 0;
          margin-left: 1rem;
        }
      }

      .shido_disclose {
        margin-top: 2rem;
        padding: 2rem 2.6rem;
        background-color: $basecolor-5;
        border-radius: 1.3rem;

        @media (max-width: 575px) {
          padding: 2rem 2rem;
        }

        h5 {
          font-size: 1.8rem;
          line-height: 2.5rem;
          margin-bottom: 2rem;

          @media (max-width: 575px) {
            font-size: 1.4rem;
          }
        }

        p {
          font-size: 1.6rem;
          color: $light-red;

          @media (max-width: 575px) {
            font-size: 1.4rem;
          }
        }
      }
    }

    .shido_left {
      .shido_stake {
        margin-top: 2rem;

        .shido_unstyle {
          flex-wrap: wrap;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid $darkblue;
          @media (max-width: 480px) {
            display: block;
          }

          li {
            .list_data {
              h4 {
                font-size: 1.6rem;

                span {
                  color: $light-blue;
                  margin-left: 0.8rem;
                }
              }

              p {
                margin-top: 0.5rem;
                color: $darkgrey;
                font-size: 1.4rem;
              }
            }
          }
        }
      }

      .shido_graph {
        margin-top: 2.2rem;
        h4 {
          font-size: 2rem;
        }
        .benchmark_return {
          @media (max-width: 767px) {
            display: block !important;
          }

          h4 {
            font-size: 2rem;
          }

          .benchmark_left {
            margin-top: 0.6rem;

            p {
              font-size: 1.4rem;
              color: $light-blue;
              @media (max-width: 575px) {
                font-size: 1.2rem;
              }

              span {
                color: $textdarklgrey;
                margin: 0 0.3rem;
              }
            }
          }

          .benchmark_right {
            .pool_data {
              align-items: center;

              .blue_dot {
                width: 9px;
                height: 9px;
                background-color: $darkblue-2;
                border-radius: 50px;
                margin-right: 0.5rem;
              }

              p {
                margin-bottom: 0;
                font-size: 1.4rem;
                color: $light-blue;
                @media (max-width: 575px) {
                  font-size: 1.2rem;
                }
              }
            }

            .return_data {
              align-items: center;
              margin-left: 1.7rem;

              @media (max-width: 575px) {
                margin-left: 1rem;
              }

              .green_dot {
                background-color: $darkgreen;
                border-radius: 50px;
                width: 9px;
                height: 9px;
                margin-right: 0.5rem;
              }

              p {
                margin-bottom: 0;
                font-size: 1.4rem;
                color: $light-blue;
                @media (max-width: 575px) {
                  font-size: 1.2rem;
                }
              }
            }
          }
        }

        .graph_img {
          margin-top: 3.5rem;
        }
      }
    }
  }
}

/*balance card css*/

.balance_card {
  margin-top: 2rem;

  .balance_box {
    background-color: $basecolor-6;
    border-radius: 2rem;
    padding: 2.5rem 2rem;

    .balance_img {
      margin-bottom: 1rem;
    }

    h4 {
      font-size: 1.6rem;
      color: $white;
    }
    p {
      margin-top: 0.5rem;
      color: $darkgrey;
      font-size: 1.4rem;
      margin-bottom: 1.6rem;
    }

    .balance_stake {
      .stake_data {
        &:last-child {
          margin-left: 2rem;
        }
        p {
          color: $light-blue;
          font-size: 1.2rem;
          margin: 0;
          margin-left: 0.9rem;
        }
      }
    }
  }
}
