@import "../../../../Assets/Theme/_var.scss";

.addToken_Modal {
  .modal {
    &-dialog {
      width: 100%;
      // max-width: 90rem;
    }

    &-content {
      // margin: 0 2rem;

      .form-control {
        background-color: $baseColor-3;
      }
    }
  }
  .blueText {
    @media (max-width: 767px) {
      font-size: 1.3rem;
    }
  }
}

.search-token {
  margin: 2rem 0 1rem;
  border: 0.1rem solid $border-clr-2;
  padding: 1rem 2rem !important;
  border-radius: 1.2rem;

  &__icon {
    width: 3rem;
    height: 3rem;
  }

  &__recordImg {
    width: 10rem;
    height: auto;
    margin-bottom: 1.5rem;
  }

  &__content {
    span {
      font-size: 1.3rem;
      color: $light-gray;
    }
  }

  .button-style {
    font-size: 1.3rem;
    padding: 1rem 1.5rem;
  }
}

.Terms_And_Conditions {
  margin: 1.5rem 0 0;

  p {
    @media (max-width: 767px) {
      font-size: 1.2rem;
      line-height: 1.3;
    }
  }

  .danger {
    color: $redTxt;
    font-size: 1.8rem;
    font-weight: 600;
    @media (max-width: 767px) {
      font-size: 1.6rem;
    }
  }

  .chkbox {
    margin: 10px 0;
    display: block !important;

    label {
      @media (max-width: 767px) {
        font-size: 1.3rem;
      }
    }
  }
}

.disclaimer_text {
  margin-top: 1.2rem;
  font-size: 1.4rem;
  display: block;

  @media (max-width: 767px) {
    font-size: 1.2rem;
    line-height: 1.3;
  }
}

.Common_Modal_Content {
  .loading_area {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    height: 220px;
    display: flex;
    align-items: center;

    .nb-spinner {
      width: 80px;
      height: 80px;
      margin: auto;
      background: transparent;
      border-top: 4px solid #1ba7fa;
      border-right: 3px solid transparent;
      border-radius: 50%;
      -webkit-animation: 1s spin linear infinite;
      animation: 1s spin linear infinite;
    }
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
