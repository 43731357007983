@import "../../../Assets/Theme/_var.scss";

.dropdown {
  .dropdown-toggle {
    background-color: transparent;
    border: none;
    font-size: 1.8rem;
    font-weight: 600;
    padding: 0;

    &::after {
      display: none;
    }

    svg {
      margin-left: 0.8rem;
      // width: 1.3rem;
      // height: 1.3rem;

      @media (max-width: 767px) {
        width: 1rem;
      }
    }
  }
  .dropdown-menu {
    background-color: #37373c;
    border-radius: 8px;
    width: 100%;
    .dropdown-item {
      font-size: 1.6rem;
      font-weight: 500;
      color: $white;
      padding: 0.35rem 1.5rem;
      text-transform: uppercase;

      &:hover {
        background-color: #43434c;
      }
      .custom {
        padding: 0.5rem 0;
        font-weight: 500;
      }
    }
  }
}

.btn:first-child:active {
  background-color: transparent;
  border-color: transparent;
}
