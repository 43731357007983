@import "../../../Assets/Theme/_var.scss";

.multi-wrap {
  .multi-select {
    &__control {
      background: $basecolor-5;
      border-radius: 3rem;
      border: 0;
      font-size: 1.6rem;
      @media (max-width: 767px) {
        font-size: 1.3rem;
      }

      &--is-focused {
        box-shadow: unset;
      }
    }

    &__value-container {
      &--is-multi {
        padding: 1.525rem 1.7rem;
        @media (max-width: 1599px) {
          padding: 0.925rem 1.7rem;
        }
        @media (max-width: 767px) {
          padding: 0.675rem 1.2rem;
        }
      }
    }
    &__input-container {
      font-size: 1.6rem;
      color: $white;
      @media (max-width: 767px) {
        font-size: 1.3rem;
      }
    }
    &__placeholder {
      color: $gray;
    }

    &__indicators {
      margin-right: 1.5rem;
    }

    &__clear-indicator {
      color: $white;
    }
    &__indicator-separator,
    &__dropdown-indicator {
      display: none;
    }

    // option menu

    &__menu {
      background: $baseColor-2;
      z-index: 1;
    }
    &__option {
      &--is-focused {
        background: $baseColor;
      }
    }

    &__menu-notice {
      &--no-options {
        color: $gray;
      }
    }

    // internal div

    &__multi-value {
      background: $baseColor;
      padding: 0.3rem;
      border-radius: 3rem;

      &__label {
        color: $white;
        font-size: 1.1rem;
        font-weight: 500;
        .multi-options {
          img {
            width: 2.2rem;
          }
        }
      }

      &__remove {
        &:hover {
          background-color: $baseColor;
        }
      }
    }
  }
}

.multi-options {
  img {
    width: 3rem;
    margin-right: 0.5rem;
  }
}
