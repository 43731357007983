@import "../../Assets/Theme/_var.scss";

.primary-layout {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__inner {
    //padding-top: 6.5rem;
    padding-bottom: 6rem;
    z-index: 0;
    flex: 1 0 auto;
  }
}
