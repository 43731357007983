@import "~bootstrap/scss/bootstrap";
@import "./Assets/Theme/_var.scss";

html {
  // font-size: 60.6666%;
  font-size: 62.5%;

  &::-webkit-scrollbar {
    display: none;
  }
}

body {
  margin: 0;
  font-family: $baseFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: $body;
  background: $darkgrey1;
  color: $white;
  // background-image: url(./Assets/Images/main-bg.jpg);
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  font-size: 1.6rem;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

.nav-link {
  font-size: 1.6rem;
}

img,
svg {
  max-width: 100%;
}

html,
body,
#root {
  height: 100%;
  overflow-x: hidden;
}

.commn-cont {
  &.container {
    max-width: 1740px;
  }
}

.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
  max-width: 1430px;
  //padding-left: 1.8rem;
  //padding-right: 1.8rem;

  @media (max-width: 575px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) * 1);
  padding-left: calc(var(--bs-gutter-x) * 1);
}

.row {
  --bs-gutter-x: 1.5rem;
  margin-right: calc(-1 * var(--bs-gutter-x));
  margin-left: calc(-1 * var(--bs-gutter-x));
}

.inner-cont {
  &.container {
    max-width: 1440px;
  }
}

h4 {
  font-size: 2.2rem;
  font-weight: 500;

  @media (max-width: 767px) {
    font-size: 2rem;
  }
}

h5 {
  // font-size: 1.8rem;
  font-size: 1.6rem;

  font-weight: 500;
}

h6 {
  font-size: 1.6rem;
  font-weight: 400;
}

p {
  font-size: 1.6rem;
  font-weight: 400;
}

.py-80 {
  padding-top: 8rem;
  padding-bottom: 8rem;

  @media (max-width: 767px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.py-70 {
  padding-top: 7rem;
  padding-bottom: 7rem;

  @media (max-width: 767px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.Bottomline_tabs {
  &.nav {
    .nav-link {
      padding: 1.5rem 2rem;
      color: $light-white;
      border-bottom: 2px solid transparent;
      border-radius: 0;
      // font-size: 1.8rem;
      font-size: 1.6rem;

      font-weight: 300;

      @media (max-width: 1679px) {
        padding: 1.2rem 1.5rem;
        // font-size: 1.6rem;
      }

      @media (max-width: 767px) {
        padding: 1rem;
        font-size: 1.3rem;
      }

      &:first-child {
        padding-left: 1rem !important;
      }

      &.active {
        background-color: transparent;
        color: $white;
        border-color: $light-blue;
        font-weight: 500;
      }
    }
  }
}

.Bottomline_tabs {
  a {
    padding: 3rem 1rem;
    margin: 0 1.2rem;
    color: $white;
    border-bottom: 0.3rem solid transparent;
    border-radius: 0.3rem;
    text-transform: uppercase;
    // font-size: 1.8rem;
    font-size: 1.6rem;

    line-height: 1.8rem;
    font-weight: 500;
    display: inline-block;

    @media (max-width: 1679px) {
      padding: 2.2rem 1rem;
      // font-size: 1.6rem;
    }

    @media (max-width: 767px) {
      padding: 1rem;
      font-size: 1.3rem;
    }

    @media (max-width: 575px) {
      margin: 0 0.5rem;
      padding: 1rem 0.5rem;
      font-size: 1.1rem;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.active {
      background-color: transparent;
      color: $white;
      border-color: $skyblue;

      &.notActive {
        background-color: transparent;
        color: $white;
        border-color: transparent;
      }
    }
  }
}

.bluelightclr,
.blueText {
  color: $skyblue !important;

  &:hover {
    color: $skyblueDark !important;
  }
}

.blueText_with_pointer {
  cursor: pointer;
}

.greenText {
  color: $darkgreen !important;
}

.oceanGreenText {
  color: $Ocean-green !important;
}

.redText {
  color: $light-red !important;
}

.orangeText {
  color: $Bar-Orange !important;
}

.modal {
  .modal-dialog {
    .modal-content {
      border: 0.2rem solid $baseColor-8;
      background: rgba($darkblue2, 1);
      border-radius: 2.6rem;

      position: relative;

      &::before {
        content: "";
        content: "";
        display: inline-block;
        width: 100%;
        height: 100%;
        background: $gradientLight;
        border-radius: 2.6rem;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        bottom: 0;
      }

      .modal-header {
        border: 0;
        padding: 2.7rem 3.4rem;
        display: block;
        background-color: transparent;
        border-radius: 2.6rem 2.6rem 0 0;
        z-index: 1;
        // box-shadow: inset 0px 7px 12px #ffffff1c;

        @media (max-width: 767px) {
          padding: 2rem;
        }

        @media (max-width: 767px) {
          padding: 2rem 2rem;
        }

        .modal-title {
          font-size: 2.4rem;
          line-height: 2.4rem;
          font-weight: 600;
          color: $white !important;
          text-transform: uppercase;
          text-align: left;

          @media (max-width: 991px) {
            font-size: 2rem;
          }

          @media (max-width: 575px) {
            font-size: 1.6rem;
          }
        }

        .btn-close {
          position: absolute;
          width: 1.6rem;
          height: 1.6rem;
          right: 2.5rem;
          top: 2.5rem;
          z-index: 9;
          opacity: 1;
          background-image: url(./Assets/Images/close_icon.svg);
          background-size: 3rem;
          border-radius: 50px;
          padding: 0.7rem;

          @media (max-width: 575px) {
            width: 1.3rem;
            height: 1.3rem;
            background-size: 2.4rem;
            top: 2rem;
            right: 2rem;
          }
        }
      }

      .modal-body {
        padding: 3rem 3.4rem;
        background: transparent;
        border-radius: 0 0 2.6rem 2.6rem;
        z-index: 1;

        @media (max-width: 767px) {
          padding: 2rem 2rem;
        }
      }
    }

    @media (max-width: 575px) {
      max-width: 94%;
      margin: 1rem auto;
    }
  }
}

.modal-backdrop.show {
  background: $baseColor-9;
  opacity: 0.8;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.form-control {
  // background-color: $darkblue;
  background-color: transparent;
  border-color: $darkblue;
  // border-radius: 30rem;
  font-size: 1.6rem;
  padding: 1.5rem 1.5rem;
  color: $white;

  @media (max-width: 991px) {
    font-size: 1.4rem;
  }

  @media (max-width: 575px) {
    font-size: 1.2rem;
  }

  &:focus {
    box-shadow: 0 0 0 0.1rem $light-blue;
    background-color: transparent;
    color: $white;
  }

  &:disabled {
    background-color: $darkblue;
  }

  @media (max-width: 1439px) {
    padding: 1rem 1.5rem;
  }
}

// .form-group {
//   &-inner {
//     position: relative;

//     &.icon-input {
//       .form-control-icon {
//         position: absolute;
//         top: 50%;
//         left: 1.6rem;
//         transform: translateY(-50%);
//         display: block;
//         max-width: 24px;
//       }

//       .form-control {
//         padding-left: 5rem;
//       }
//     }

//     &.icon-input-right {
//       .form-control-icon-right {
//         position: absolute;
//         top: 50%;
//         right: 1.6rem;
//         transform: translateY(-50%);
//         display: block;
//         max-width: 24px;

//         &.icon-text {
//           max-width: fit-content;
//         }
//       }

//       .form-control {
//         padding-right: 5.4rem;
//       }
//     }
//   }
// }

.accordion {
  background-color: transparent;

  .accordion-item {
    background-color: transparent;
    border: 0;
    padding: 0.7rem 0;

    .accordion-button {
      background-color: transparent;
      padding: 0;
      border: 0;
      box-shadow: none;
      font-size: 1.4rem;
      font-weight: 500;
      color: $white;
      text-transform: uppercase;

      &:focus {
        box-shadow: none;
      }
    }

    .accordion-collapse {
      .accordion-body {
        color: $light-white;
      }
    }
  }
}

.form-switch {
  padding: 0;

  .form-check-input {
    width: 3.4rem;
    height: 2.1rem;
    cursor: pointer;

    &:checked {
      background-color: $primary-clr;
      border-color: $primary-clr;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.common_tabs {
  &_nav {
    align-items: center;
    background-color: transparent;
    border-radius: 3rem 3rem 0 0;
    padding: 0 3rem;

    .nav-link {
      font-size: 1.6rem;
      padding: 1.2rem;
      color: $white;
      font-weight: 500;
      text-transform: uppercase;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        background-color: $light-blue;
        height: 0.3rem;
        width: 72%;
        left: 50%;
        transform: translateX(-50%);
        border-bottom-left-radius: 0.4rem;
        border-bottom-right-radius: 0.4rem;
        bottom: 0;
        opacity: 0;
      }

      &.active {
        &::after {
          opacity: 1;
        }
      }
    }

    @media (max-width: 991px) {
      padding: 0 2rem;

      .nav-link {
        font-size: 1.4rem;
      }
    }

    @media (max-width: 767px) {
      .nav-link {
        padding: 1rem 1.4rem;
      }
    }

    @media (max-width: 489px) {
      .nav-link {
        padding: 1rem 0.6rem;
        display: block;
        width: 100%;
        font-size: 1.3rem;
      }
    }
  }

  &_content {
    background: $gradient;
    padding: 3rem;
    border-radius: 0 0 3rem 3rem;

    @media (max-width: 991px) {
      padding: 2rem;
    }
  }
}

.text_light {
  opacity: 0.5;
}

.gradient-bg {
  position: relative;

  &::after {
    content: "";
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    background: $gradient;
    border-radius: 3rem;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
  }
}

.Common_SearchBar {
  .form-control {
    background-color: #04132a;
    font-weight: 500;
  }
}

.chainge_transactions {
  color: $white;
  padding: 2rem;
  background-color: $baseColor-4;
  border-radius: 1rem;
  text-align: center;
  min-width: 550px;
}

.no_internet_connection {
  color: whitesmoke;
  //background-color: red;
}

.error_fallback {
  &__inner {
    text-transform: uppercase;

    h2 {
      font-size: 2.4rem;
      font-weight: 400;
      // font-style: italic;
      margin-bottom: 1rem;

      @media (max-width: 767px) {
        font-size: 1.8rem;
      }
    }

    p {
      font-size: 1.6rem;
      color: $light-gray;
      margin-bottom: 3rem;

      @media (max-width: 767px) {
        font-size: 1.3rem;
        margin-bottom: 2rem;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer !important;
}
.warning_wrap {
  > div {
    margin-top: -1rem;
  }
}

.low_dollarWorth_warning,
.order_execution_warning,
.limit_price_below_market {
  font-size: 1.3rem;
  border-radius: 1rem;
  background: $baseColor;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.low_dollarWorth_warning {
  color: $Bar-Darkred;
}

.order_execution_warning {
  color: $Bar-Orange;
}

.order_execution_warning {
  position: relative;
  button {
    position: absolute;
    width: 2rem;
    height: 2rem;
    border: 0;
    padding: 0;
    background-color: transparent;
    right: -0.5rem;
    top: -0.5rem;
  }
}

.limit_price_below_market {
  color: $Bar-Lightred;
  span {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    display: inline-block;
    svg circle,
    svg line {
      stroke: $Bar-Lightred;
    }
  }
}

.fw-700 {
  font-weight: 700 !important;
}

/* OneInchSwap.scss */
.ant-select-selector {
  color: white !important; /* Ensures text color is white */
}

.ant-select .ant-select-selection-placeholder {
  color: rgba(255, 255, 255, 0.5); /* Lighter white color for the placeholder */
}

/* Increase the font size and padding for the Select component */
.ant-select-selector {
  font-size: 16px; /* Larger font size */
  padding: 8px 12px; /* More padding for better visibility */
}

/* Dropdown menu styles */
.ant-select-dropdown {
  font-size: 16px; /* Larger font for dropdown items */
}

.ant-select-dropdown .ant-select-item {
  padding: 10px 20px; /* More padding for dropdown items */
}

