@import "../../../Assets/Theme/_var.scss";

.Commom_Table {
  margin-top: 1rem;

  @media (max-width: 991px) {
    overflow-x: scroll;
  }

  .farming_tableHeading {
    margin-bottom: 2rem;

    @media (max-width: 991px) {
      display: none;
    }

    ul {
      display: flex;

      li {
        min-width: 140px;
        padding: 0 2rem;
        color: $light-blue1;
        width: 16.6666%;

        &:first-child {
          width: 310px;
        }

        &:last-child {
          // min-width: 0;
          text-align: right;
        }

        @media (max-width: 991px) {
          &:first-child {
            width: 50%;
          }

          width: 50%;
        }
      }
    }
  }

  .farming_tableData {
    ul {
      display: flex;
      // background-color: $Bg-Liquidity;
      background-color: $darkblack;
      border-radius: 2rem;
      margin-bottom: 2rem;
      align-items: center;

      @media (max-width: 991px) {
        flex-wrap: wrap;
        padding: 1rem 0;
      }

      li {
        min-width: 140px;
        font-size: 1.6rem;
        padding: 2rem;
        width: 16.6666%;

        &:first-child {
          width: 310px;
        }

        &:last-child {
          min-width: 0;
          text-align: right;
        }

        .table_data {
          .table_imgs {
            width: 8.1rem;

            .right_img {
              margin-left: -2.3rem;
            }
          }

          .table_contant {
            margin-left: 1rem;

            h5 {
              color: $light-gray;
              font-size: 1.4rem;
            }

            p {
              font-size: 1.8rem;

              @media (max-width: 767px) {
                font-size: 1.6rem !important;
              }
            }
          }

          .ended {
            margin-left: 1.2rem;
            background-color: $red-opacity;
            border-radius: 1.8rem;
            padding: 0.5rem 1rem;
            display: flex;
            width: 6.8rem;
            align-items: center;
            justify-content: center;

            @media (max-width: 767px) {
              width: 7.9rem;
            }

            span {
              font-size: 1.4rem;
              color: $light-red;
              line-height: normal;
            }
          }
        }

        @media (max-width: 991px) {
          &:first-child {
            width: 50%;
          }

          width: 50%;
          font-size: 1.2rem !important;
          border-bottom: 1px solid $border-clr-2;

          &:last-child {
            text-align: left;
            border: 0;
          }
        }

        @media (max-width: 767px) {
          padding: 1rem 1rem;

          &:first-child,
          &:last-child {
            width: 100%;
          }
        }
      }
    }
  }

  .Mobile_Table {
    font-size: 1.4rem;

    h6 {
      display: none;
      color: $darkblue-2;
      font-size: 1.4rem;

      @media (max-width: 991px) {
        display: block;
      }
    }
  }
}
