@import "../../../Assets/Theme/_var.scss";

.select-wrap {
  position: relative;
  .select-blockchain {
    &__control {
      border: none;
      border-radius: 8px;
      background-color: #37373c;
      height: min-content;
      min-width: 18rem;
      cursor: pointer;

      input {
        height: 100%;
        //padding: 12px 20px;
      }

      // @media (max-width: 1439px) {
      //   min-width: 14rem;
      // }

      @media (max-width: 575px) {
        min-width: auto;
        min-height: unset;
        height: 3.4rem;
      }

      .select-blockchain__placeholder {
        color: $white;
      }

      &--is-focused {
        box-shadow: unset;
      }
    }

    &__value-container {
      padding-left: 0.8rem;
      height: inherit;
    }

    &__single-value {
      width: fit-content;
      .blockchain-options {
        &.disable {
          opacity: 0.6;
        }
      }
    }

    &__single-value,
    &__input-container {
      margin: 0;
      color: $white;
      font-size: 1.4rem;
      //height: inherit;
      font-weight: 500;

      @media (max-width: 1439px) {
        font-size: 1.2rem;
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__dropdown-indicator {
      height: 100%;
      padding: 0 0.6rem;
      display: flex;

      @media (max-width: 575px) {
        padding: 0;
        padding-right: 0.5rem;

        svg {
          margin: auto 0;
          max-width: 1.4rem;
        }
      }

      svg {
        margin: auto 0;
        fill: $white;
      }
    }

    &__menu {
      background: #37373c;
      border-radius: 1.2rem;
      overflow: hidden;
      z-index: 2;
      width: max-content;

      @media (max-width: 991px) {
        width: 100%;
        margin: 1rem 0.5rem;
        font-size: 1.3rem;
      }

      @media (max-width: 575px) {
        width: 100%;
        right: 0;
      }
    }

    &__option {
      cursor: pointer;
      align-items: start;

      &--is-selected {
        background-color: #0086ff !important;
        border-radius: 8px;

        &.select-blockchain__option--is-focused {
          background-color: #0086ff;
          border-radius: 8px;
        }
      }

      &--is-disabled {
        cursor: not-allowed;
      }

      &--is-focused {
        background-color: transparent;
      }

      &:hover {
        background-color: #46464d;
        border-radius: 8px;
      }

      //&:not(:last-child) {
      //  border-bottom: 0.1rem solid;
      //  border-image-slice: 1;
      //  border-width: 0.1rem;
      //  border-image-source: $border-gradient;
      //}
    }
  }
  .switch_network_warning {
    position: absolute;
    top: calc(100% + 20px);
    max-width: 280px;
    background-color: $baseColor-7;
    border-radius: 8px;
    width: max-content;
    right: 0;
    z-index: 100;
    padding: 10px;
    font-size: 12px;
    &::before {
      content: "";
      width: 14px;
      height: 14px;
      background-color: inherit;
      transform: rotate(45deg) translate(-4px, -50%);
      left: 90%;
      top: 0;
      position: absolute;
    }
    @media (max-width: 575px) {
      position: absolute;
      right: -20px;
      &::before {
        left: 80%;
      }
    }
  }

  &.select-chain {
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1rem;
    .select-title {
      text-transform: capitalize;
      font-size: 1.4rem;
      font-weight: 400;
      margin-right: 1.5rem;
      min-width: 4rem;

      @media (max-width: 575px) {
        font-size: 1.2rem;
        margin-right: 1rem;
      }
    }

    .select-blockchain {
      &__control {
        border-radius: 8px;
        background-color: #37373c !important;
        border: 0;
      }
      &__single-value {
        .blockchain-options {
          @media (max-width: 575px) {
            img {
              margin-right: 0.5rem;
            }

            span {
              display: flex;
            }
          }
        }
      }
    }
  }
}

.blockchain-options {
  text-transform: capitalize;
  font-size: 14px;
  line-height: 2.6rem;
  font-weight: 600;
  width: 100%;
  @media (max-width: 1439px) {
    font-size: 1.2rem;
  }

  img {
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 10rem;
    margin-right: 0.8rem;
  }

  &.disable {
    opacity: 0.6;
    display: flex;
    align-items: center;
    line-height: 1;
    span {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
    }
    small {
      font-size: 0.6rem;
      padding: 0.5rem;
      border-radius: 1.2rem;
      margin-left: 1rem;
      background-color: $light-blue;
    }
  }
}
