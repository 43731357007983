@import "../../../Assets/Theme/_var.scss";

.Simple_Swap_Card {
  max-width: 600px;
  // max-width: 500px;
  width: 100%;
  margin: 0 auto;
  background: rgba($darkblack, 0.48);
  border-radius: 3rem;
  padding: 3rem;
  // margin-top: 3.2rem;
  padding: 2.2rem 2.5rem;
  margin-top: 2rem;
  text-transform: uppercase;

  // @media (max-width: 1439px) {
  //   max-width: 450px;
  // }

  @media (max-width: 767px) {
    padding: 2.5rem 1.5rem;
  }

  .token_buyPrice {
    margin-left: 0.6rem;
  }

  .token_sellPrice {
    margin-left: 0.6rem;
  }

  .buySell_Price {
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    p {
      color: $white;
      // font-size: 1.8rem;
      font-size: 1.6rem;
      font-weight: 400;
      margin-bottom: 0;
      span {
        font-weight: 700;
      }
      @media (max-width: 575px) {
        font-size: 1.2rem;
      }
    }

    h6 {
      // font-size: 1.8rem;
      font-size: 1.6rem;
      padding-left: 1rem;
      text-align: right;
      font-weight: 400;
      margin-bottom: 0;

      @media (max-width: 575px) {
        font-size: 1.2rem;
      }

      span {
        color: $skyblue;
        // font-size: 1.8rem;
        font-size: 1.6rem;
        font-weight: 400;
        @media (max-width: 575px) {
          font-size: 1.2rem;
        }
      }

      &.tx_cost {
        display: flex;
        align-items: center;
        font-weight: 500;

        .custom_shimmer {
          margin-left: 4px;
        }
      }
    }

    .or-btn {
      position: relative;
      top: -0.5rem;
      margin-left: 2rem;
    }
  }

  .Hide_show_Tx {
    line-height: 2.3rem;
    cursor: pointer;

    img {
      position: relative;
      top: -2px;
    }
  }

  span {
    &.Hide_show_Tx {
      font-size: 1.3rem !important;
      @media (max-width: 575px) {
        font-size: 1.2rem !important;
      }
    }
  }

  // .tooltip_container {
  //   position: relative;

  //   .hover_tooltip {
  //     visibility: hidden;
  //     opacity: 0;
  //     transition: 0.3s ease-in-out;
  //     position: absolute;
  //     bottom: calc(100% + 0.5rem);
  //     // left: 50%;
  //     // transform: translate(-50%);
  //     right: -5px;
  //     border-radius: 1rem;
  //     padding: 1.4rem 1.6rem 1rem;
  //     display: block;
  //     background: rgba($color: $border-clr-2, $alpha: 0.9);
  //     max-width: 200px;

  //     span {
  //       font-size: 1.4rem;
  //       max-width: 100%;
  //       white-space: nowrap;
  //       text-overflow: ellipsis;
  //       overflow: hidden;
  //       color: $white;
  //       display: inline-block;
  //     }

  //     &::after {
  //       content: "";
  //       width: 1rem;
  //       height: 1rem;
  //       transform: rotate(45deg);
  //       background: inherit;
  //       position: absolute;
  //       top: calc(100% - 0.5rem);
  //       left: 80%;
  //     }

  //     @media (max-width: 991px) {
  //       span {
  //         font-size: 1.2rem;
  //         padding: 0.8rem 1.4rem;
  //         padding-top: 1.2rem;
  //       }
  //     }

  //     @media (max-width: 575px) {
  //       left: unset;
  //       right: 100%;
  //       top: 50%;
  //       transform: translateY(-50%);
  //       bottom: unset;
  //       border-radius: 0.6rem;

  //       &::after {
  //         transform: translate(-50%, -50%) rotate(45deg);
  //         top: 50%;
  //         left: 100%;
  //       }
  //     }
  //   }

  //   &:hover {
  //     .hover_tooltip {
  //       visibility: visible;
  //       opacity: 1;
  //     }
  //   }
  // }
}

.Positive_Price_Impact {
  color: green;
}

.Negative_Price_Impact {
  color: red;
}

.tooltip_container {
  position: relative;

  .hover_tooltip {
    // min-width: 15rem;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease-in-out;
    position: absolute;
    bottom: calc(100% + 0.5rem);
    // left: 50%;
    // transform: translate(-50%);
    right: 5px;
    border-radius: 1rem;
    padding: 1.4rem 1.6rem 1rem;
    display: block;
    background: rgba($color: $border-clr-2, $alpha: 0.9);
    // max-width: 200px;
    max-width: fit-content;

    span {
      font-size: 1.4rem;
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: $white !important;
      display: inline-block;
    }

    &::after {
      content: "";
      width: 1rem;
      height: 1rem;
      transform: rotate(45deg);
      background: inherit;
      position: absolute;
      top: calc(100% - 0.5rem);
      left: 80%;
    }

    @media (max-width: 991px) {
      span {
        font-size: 1.2rem;
        // padding: 0.8rem 1.4rem;
        // padding-top: 1.2rem;
      }
    }

    @media (max-width: 575px) {
      left: unset;
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
      bottom: unset;
      border-radius: 0.6rem;

      &::after {
        transform: translate(-50%, -50%) rotate(45deg);
        top: 50%;
        left: 100%;
      }
    }
  }

  &:hover {
    .hover_tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
}
