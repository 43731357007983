@import "../../../Assets/Theme/_var.scss";

.main-header {
  &.navbar {
    // border-bottom: 0.1rem solid $border-clr;
    padding: 1rem 0;

    @media (max-width: 1199px) {
      position: fixed;
      z-index: 111;
      width: 100%;
      background: $baseColor;
      box-shadow: 1.4rem 1rem 1.4rem rgba(255, 255, 255, 0.06);
    }

    .navbar-brand {
      color: $white;
      font-size: 1.7rem;
      font-weight: 400;

      @media (max-width: 767px) {
        padding-top: 0;
      }

      img {
        max-width: 20rem;
      }

      @media (max-width: 1439px) {
        font-size: 2rem;

        img {
          width: 12rem;
        }
      }

      @media (max-width: 1199px) {
        position: relative;
        z-index: 11;
      }
    }

    .connect_wallet_box {
      flex-shrink: 0;
      margin-left: 1.5rem;

      @media (max-width: 767px) {
        // z-index: 1000;
        z-index: 3;
        margin-left: 0;
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 1rem 2rem;
        background-color: $body;
        border-top: 1px solid $border-clr;
        width: 100%;
        transition: 0;

        &_inner {
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .button-style {
            border-radius: 1rem;
          }
        }
      }
    }

    @media (max-width: 1199px) {
      .navbar-toggler {
        margin-left: 1rem;
        border: 0;

        &:focus {
          box-shadow: unset;
        }

        position: relative;
        z-index: 11;
      }

      .navbar-collapse,
      .navbar-collapsing {
        padding: 2rem 0 2.5rem;
        margin-top: 6.2rem;
        position: fixed;
        height: 100vh;
        z-index: 9;
        left: 0;
        top: 0;
        bottom: 0;
        width: 30rem;
        background: $baseColor;
        transform: translateX(-45rem);

        @media (max-width: 575px) {
          margin-top: 5rem;
        }

        &.collapsed,
        &.collapse.show,
        &.collapsing,
        &.collapsing.show {
          height: 100vh !important;
          transition: 0.3s all ease-in-out !important;
        }

        &.collapse {
          transform: translateX(0);
          box-shadow: 1.4rem 1rem 1.4rem rgba(255, 255, 255, 0.06);
        }
      }
    }

    .navbar-collapse {
      .navbar-nav {
        @media (max-width: 1199px) {
          padding-bottom: 4rem;
          overflow-y: scroll;
          height: calc(100dvh - 6rem);
          overscroll-behavior: contain;
        }

        @media (max-width: 767px) {
          &::-webkit-scrollbar {
            width: 0.5rem;
          }

          &::-webkit-scrollbar-track {
            background-color: $darkblue;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: rgba($light-white, 0.5);
          }
        }
      }
    }

    @media (max-width: 767px) {
      .navbar-toggler {
        margin-left: 0;
        padding: 0;
      }
    }

    @media (max-width: 575px) {
      .navbar-collapse,
      .navbar-collapsing {
        width: 100%;
      }
    }

    @media (min-width: 1199px) {
      // .nav-item.dropdown:hover .dropdown-menu {
      //   display: block;
      // }
    }
  }

  .navlinks {
    &__item {
      display: inline-block;
      position: relative;
      cursor: pointer;
      padding: 1rem 1.5rem;
      margin: 0 0.75rem;

      @media (max-width: 1439px) {
        padding: 0.7rem 1rem;
        margin: 0 0.5rem;
      }

      @media (max-width: 1199px) {
        padding: 0;
        margin: 0;
        border-bottom: 1px solid $border-clr;
        display: block;
        width: 100%;
      }

      .down-icon {
        @media (max-width: 1199px) {
          position: absolute;
          right: 1rem;
        }
      }

      a {
        color: $white;
        text-transform: uppercase;
        // font-style: italic;
        font-size: 1.6rem;
        font-weight: 500;
        display: block;
        transition: all 0.25s linear;

        @media (max-width: 1439px) {
          font-size: 1.4rem;
        }

        svg {
          path {
            fill: $white;
            transition: all 0.25s linear;
          }
        }
      }

      .nav-link.show {
        color: $white;

        svg {
          path {
            fill: $white;
          }
        }
      }

      &:hover {
        a {
          color: $white;

          svg {
            path {
              fill: $white;
            }
          }
        }
      }

      .dropdown-toggle {
        padding: 0;

        @media (max-width: 1199px) {
          padding: 1rem 1.7rem;
        }

        &::after {
          display: none;
        }

        svg {
          margin-left: 0.3rem;
        }
      }

      .dropdown-menu {
        border-radius: 1.9rem;
        border: 0;
        padding: 0;
        background-color: #081122;
        opacity: 1;
        // background-color: transparent;
        // margin-top: 0.5rem;
        // top: 100%;
        // -webkit-transition: all 0.35s linear;
        // transition: all 0.35s linear;
        // &.show {
        //   top: 180%;
        //   -webkit-transition: all 0.35s linear;
        //   transition: all 0.35s linear;
        // }
      }

      &.perpetuals_link {
        @media (max-width: 1199px) {
          padding: 1rem 1.7rem;
        }
      }
    }
  }
}

.wrong_network_warning {
  background: rgba(239, 117, 117, 0.699);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  color: #fff;
  border-radius: 2px;
  border: 0.3px solid rgba(255, 255, 255, 0.3490196078);
}

.custom-droplink,
.custom-droplink--bridge {
  padding: 1.25rem 0;
  width: 100%;
  border: 0.1rem solid $border-clr-2;
  border-radius: 1.9rem;
  min-width: 36.8rem;
  // background: $baseColor-3;
  background: rgba($color: $darkgrey1, $alpha: 0.8);
  // rgb(8 17 34 / 80%)
  z-index: 1;
  overflow: hidden;

  @media (max-width: 1199px) {
    min-width: 100%;
    margin-top: 1rem;
    padding: 0;
    border: 0;
    border-radius: 0;
    background-color: transparent;
  }

  @media (max-height: 699px) {
    // max-height: 20.5rem;
    // overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    &::-webkit-scrollbar-track {
      background-color: $darkblue;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba($light-white, 0.5);
    }
  }

  li {
    &:not(:last-child) {
      border-bottom: 0.1rem solid;
      border-image-slice: 1;
      border-width: 0.1rem;
      border-image-source: $border-gradient;
    }

    &:first-child {
      .on_home_page {
        background: $baseColor-2;
      }
    }
  }

  a {
    display: block;
    padding: 1.2rem 2.5rem;
    font-size: 1.5rem !important;
    font-weight: 500;
    transition: all 0.25s linear;

    @media (max-width: 1439px) {
      padding: 0.4rem 2rem;
      font-size: 1.2rem !important;
    }

    @media (max-width: 1199px) {
      min-height: 4rem;
      font-size: 1.2rem !important;
    }

    &:hover {
      background: $Bg-Liquidity;
    }

    &.isActive_link {
      background: $baseColor-2;
    }

    &.custom-droplink__trade {
      display: flex;
    }
  }

  &.coming_droplink {
    .custom-droplink__icon,
    .coming_soon_title {
      opacity: 0.5;
    }
  }

  &__trade {
    display: flex;
    align-items: center;

    &.earn_dropdown_link {
      position: relative;

      .trade-text {
        &__subtext {
          position: absolute;
          right: 2rem;
          border-radius: 3rem;
          background-color: $skyblue;
          color: $white;
          padding: 0.2rem 0.8rem;
          font-size: 1rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .trade-text {
      color: $white;

      &__subtext {
        // font-size: 1.4rem;
        // line-height: 2.8rem;
        font-size: 1.2rem;
        line-height: 2rem;
        color: $skyblue;

        @media (max-width: 1199px) {
          font-size: 1rem;
          line-height: 1.6rem;
        }
      }

      &.coming_soon_text {
        white-space: nowrap;
        border-radius: 3rem;
        background-color: $skyblue;
        padding: 0.2rem 0.8rem;
        font-size: 1rem;

        .trade-text__subtext {
          color: $white;
        }
      }
    }
  }

  &__icon {
    min-width: 3.5rem;

    @media (max-width: 1199px) {
      min-width: 2.5rem;
    }

    img {
      @media (max-width: 1199px) {
        width: 1.5rem;
      }
    }
  }

  &--bridge {
    .custom-droplink__icon {
      margin-right: 0.5rem;

      img {
        width: 3.5rem;

        @media (max-width: 1199px) {
          width: 2.5rem;
        }
      }
    }
  }
}

.coming_droplink_trade {
  opacity: 0.5;
}

.hamburger {
  position: relative;

  .line {
    width: 2rem;
    height: 0.2rem;
    background: $light-blue;
    display: block;
    margin: 0.5rem auto;
    transition: all 0.3s ease-in-out;
  }

  &::before {
    content: "";
    position: absolute;
    width: 3rem;
    height: 3rem;
    border: 0.3rem solid transparent;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    transition: all 0.3s ease-in-out;
  }

  &.collapsed {
    transform: rotate(45deg);

    &::before {
      border: 0.3rem solid $light-blue;
    }

    .line {
      width: 1.8rem;
      background: $light-blue;

      &:nth-child(1) {
        transform: translateY(0.7rem);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: translateY(-0.7rem) rotate(90deg);
      }
    }
  }
}
