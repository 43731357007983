.sider-body {
  background-color: #252527;
  border-radius: 20px;
  padding: 20px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .logo {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      height: 48px;
    }
  }
}
.ant-menu-root {
  li {
    margin-bottom: 8px !important;
  }
  .item-without-children {
    height: 44px;
    padding: 12px;
    border-radius: 8px;
    margin-top: unset;
    margin-right: unset;
    margin-left: unset;
    .ant-menu-item-icon {
      padding: 12px;
    }
  }
}

.ant-menu-root {
  .ant-menu-item-selected.item-without-children {
    background-color: #3b87f7 !important;
    color: white !important;
    svg {
      fill: white;
      stroke: white;

      path {
        fill: white;
        stroke: white;
      }
    }
  }
}

.shido-sider-menu {
  .ant-menu-submenu {
    .ant-menu-submenu-title {
      width: unset;
      margin: unset;
      padding: unset;
      font-weight: 500;
      height: 44px;
      padding-left: 10px !important;

      .ant-menu-item-icon {
        padding: 12px;
      }

      .ant-menu-title-content {
        color: white;
        font-weight: 600;
        text-align: start;
      }
    }

    .ant-menu-sub {
      .ant-menu-item {
        padding-left: 60px !important;
        text-align: start;
      }
    }
  }

  .ant-menu-item {
    color: white;
    font-weight: 500;
    padding-left: 10px !important;
    text-align: start;
  }

  .ant-menu-item-selected {
    color: #0086ff;
    //svg {
    //  fill: white;
    //  stroke: white;
    //
    //  path {
    //    fill: white;
    //    stroke: white;
    //  }
    //}
  }
}
