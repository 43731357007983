@import "../../../Assets/Theme/_var.scss";

.Trade_Simple_Card {
  //max-width: 602px;
  width: 100%;
  margin: 0 auto;
  // background: $bordergradient;
  border-radius: 3rem;
  // padding: 0.2rem;

  // @media (max-width: 1439px) {
  //   max-width: 450px;
  // }

  &.P2PTrade_Cards {
    padding: 0;
    background: transparent;
  }

  // &::after {
  //   content: "";
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   left: 0;
  //   top: 0;
  //   border-radius: 3rem;
  //   background: $border_gradient;
  //   z-index: -1;
  // }

  .Trade_Tabs_card {
    position: relative;
    border-radius: 3rem;
    // overflow: hidden;
    background: $darkblue2;

    &::after {
      // content: "";
      // content: "";
      display: inline-block;
      width: 100%;
      height: 100%;
      background: $gradient;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      bottom: 0;
    }

    &__title {
      margin-bottom: 0;
      padding: 3rem 3.2rem 0;
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 700;
      @media (max-width: 767px) {
        font-size: 1.6rem;
        padding: 2rem 3rem 0;
      }
      @media (max-width: 575px) {
        font-size: 1.2rem;
        padding: 1.5rem 1.3rem 0;
      }
    }

    .Trade_Tabs {
      background-color: transparent;
      border-radius: 3rem 3rem 0 0;
      padding: 0 3.2rem;
      @media (max-width: 480px) {
        padding: 0 2rem;
      }

      .numeric_two {
        display: inline-block;
      }

      .notActive {
        opacity: 0.3;
      }

      .cross-chain {
        opacity: 0.8;
      }

      .Bottomline_tabs {
        a {
          @media (max-width: 1679px) {
            font-size: 1.4rem;
            margin: 0 0.5rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }
          @media (max-width: 479px) {
            font-size: 1.2rem;
            margin: 0 0.25rem;
          }
        }
      }
    }
  }

  .Trade_Tabs_content {
    padding: 3.2rem 3.5rem;
    @media (max-width: 1679px) {
      padding: 2.5rem;
    }

    @media (max-width: 479px) {
      padding: 5rem 1.2rem 2rem;
    }
  }

  .Trade_options {
    position: absolute;
    top: 2.8rem;
    right: 3rem;

    @media (max-width: 1679px) {
      top: 2rem;
    }

    @media (max-width: 767px) {
      top: 0.9rem;
    }
    @media (max-width: 479px) {
      right: 1.5rem;
      top: 3rem;
    }

    span {
      cursor: pointer;
      margin-left: 1rem;
      @media (max-width: 575px) {
        margin-left: 0.5rem;
      }

      svg {
        width: 1.8rem;
        height: 1.8rem;
        @media (max-width: 575px) {
          width: 1.4rem;
          height: 1.4rem;
        }
      }

      &.refresh_icon {
        svg {
          width: 2.6rem;
          height: 2.6rem;

          @media (max-width: 575px) {
            width: 2.2rem;
            height: 2.2rem;
          }
        }
      }

      // &:first-child {
      //   img {
      //     width: 18px;
      //     margin-right: 8px;
      //     height: 20px;

      //     @media (max-width: 480px) {
      //       width: 18px;
      //       height: 18px;
      //       margin-right: 4px;
      //     }
      //   }
      // }
    }
  }
}

// .responsive-icon {
//   svg,
//   img {
//     width: 27px;
//     height: 26px;

//     @media (max-width: 575px) {
//       max-width: 1.8rem;
//     }
//   }
//   &.plus-icon,
//   &.filter-icon {
//     max-width: 2rem;
//   }

//   &.refresh_icon {
//     @media (max-width: 575px) {
//       svg {
//         max-width: 2.2rem;
//       }
//     }
//   }
// }
