.stats {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  height: 153px;
  border-radius: 20px;
  border: 1px solid var(--Secondary-600, #37373c);
  background: var(--Secondary-700, #252527);
  .statistic-items-mobile {
    display: none;
  }
  .statistic-items {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
    .statistic-item {
      display: flex;
      flex-direction: column;
      width: inherit;
      .text {
        text-align: start;
      }
    }
  }
}

@media (min-width: 993px) and (max-width: 1280px) {
  .stats {
    height: auto;
    .statistic-items {
      display: none;
    }
    .statistic-items-mobile {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .item-row1 {
        width: inherit;
      }
      .item-row2 {
        width: inherit;
      }
      .statistic-item {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        .text {
          text-align: start;
        }
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .stats {
    height: auto;
    .statistic-items-mobile {
      display: none;
    }
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .stats {
    height: auto;
    padding: 20px;
    .statistic-items-mobile {
      display: none;
    }
  }
}

@media (min-width: 200px) and (max-width: 576px) {
  .stats {
    height: auto;
    padding: 20px;
    .statistic-items {
      display: none;
    }
    .statistic-items-mobile {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .item-row1 {
        width: inherit;
      }
      .item-row2 {
        width: inherit;
      }
      .statistic-item {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        .text {
          text-align: start;
        }
      }
    }
  }
}
