@import "../../../../Assets/Theme/_var.scss";

.gas_title {
  h5 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 8px;
    color: $white;
  }

  p {
    font-size: 12px;
    line-height: 21px;
    margin-bottom: 0px;
    color: $light-blue;
  }
}

.gas_refund_modal {
  .modal-dialog {
    .modal-content {
      padding-bottom: 3rem;

      .Common_Modal_Content {
        max-height: 560px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 0.5rem;

        &::-webkit-scrollbar {
          width: 0.5rem;
        }

        &::-webkit-scrollbar-track {
          background-color: $darkblue;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: $light-white;
        }

        .gas_refund_Box {
          margin-top: 3rem;
          background-color: rgba(19, 37, 64, 0.5);
          border-radius: 10px;

          .refund_data {
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-items: center;
            font-size: 13px;
            line-height: 15px;
            padding: 1.5rem;
            border-bottom: 1px solid $baseColor;

            span {
              color: $primary-clr;
            }
          }

          .gas_refund_data {
            // border: 1px solid $border-clr;
            // border-radius: 1rem;

            .Token_icon_Name {
              border-bottom: 1px solid $border-clr;

              &:last-child {
                border: 0;
              }

              span {
                font-size: 1.4rem;
                color: $light-white;
                width: 50%;
                display: inline-block;
                text-align: center;
                padding: 1rem;

                &.border_right {
                  border-right: 1px solid $border-clr;
                }
              }
            }
          }
        }

        .gas_title {
          width: 62%;
          padding-right: 20px;

          @media (max-width: 767px) {
            width: 100%;
            padding-right: 0px;
            padding-bottom: 20px;
            text-align: center;
          }
        }

        .GasRefund_logo {
          width: 38%;

          @media (max-width: 767px) {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }

  .aboutgas {
    color: $primary-clr;

    &:hover {
      color: $light-blue;
    }
  }
}
