@import "../../../Assets/Theme/_var.scss";
.Simple_Swap_Card {
  .Txcost_Market_Card {
    display: none;
  }
  &.HideShow {
    .Txcost_Market_Card {
      display: block;
    }
  }
}
.Txcost_Market_Card {
  margin: 1rem 0;
  border-top: 1px solid $border-clr;
  padding-top: 1rem;
  text-transform: uppercase;
  .accordion {
    background-color: transparent;

    .accordion-item {
      background-color: transparent;
      border: 0;
      padding: 0.7rem 0;

      .accordion-button {
        background-color: transparent;
        padding: 0;
        border: 0;
        box-shadow: none;

        &::after {
          display: none;
        }

        &:focus {
          box-shadow: none;
        }

        .Co_ratioBtn {
          width: 16px;
          height: 16px;
          border: 1px solid $light-blue;
          border-radius: 10rem;
          position: relative;
        }

        &[aria-expanded="true"] {
          .Co_ratioBtn::after {
            width: 10px;
            height: 10px;
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            background-color: $light-blue;
            border-radius: 10rem;
            transform: translate(-50%, -50%);
          }
        }
      }

      .accordion-collapse {
        .accordion-body {
          padding: 1.2rem 0 0 3rem;
          color: $white;

          p,
          h6 {
            color: $white;
            font-size: 1.4rem;
            font-weight: 500;
          }

          p {
            font-weight: 400;
            text-transform: uppercase;
            margin-bottom: 0.7rem;
            img {
              width: 1.1rem;
              margin-left: 0.5rem;
              cursor: pointer;
              position: relative;
              top: -0.2rem;
            }
          }
          h6 {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .Low_Gas_Warning {
    background: $darkblack;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    color: $light-blue;
    border-radius: 1.5rem;
    font-size: 1.3rem;
    font-weight: 500;
    margin-top: 1.5rem;

    span {
      margin-right: 2rem;
    }
  }

  .Txcost_market_list {
    width: calc(100% - 16px);
    padding-left: 1.5rem;

    @media (max-width: 575px) {
      padding-left: 1rem;
    }

    .Txcost_market_L {
      margin-right: 1rem;
      img {
        margin-right: 1.2rem;
        @media (max-width: 575px) {
          margin-right: 0.6rem;
        }
      }
    }

    p {
      color: $white;
      // font-size: 1.6rem;
      font-size: 1.4rem;
      margin-bottom: 0;
      line-height: normal;
      font-weight: 400;

      @media (max-width: 575px) {
        font-size: 1.2rem;
      }

      span {
        // font-style: italic;
        // font-size: 1.8rem;
        // font-weight: 500;
        font-size: 1.6rem;
        font-weight: 500;
        margin-right: 0.8rem;
        @media (max-width: 575px) {
          font-size: 1.4rem;
          margin-right: 0.4rem;
        }
      }

      &.price {
        span {
          font-size: 1.6rem;
          margin-left: 0.8rem;
          margin-right: 0;
          font-weight: 400;
          font-style: normal;
          @media (max-width: 575px) {
            font-size: 1.2rem;
            margin-left: 0.4rem;
          }
        }
      }
    }
  }
}

.Txcost_Changes {
  .inputGroup {
    position: relative;
    margin-bottom: 1rem;

    .form-control {
      background-color: #0f1b2f;
      border-color: #0f1b2f;
      border-radius: 30rem;
      font-size: 2rem;
      font-weight: 500;
      padding: 0.6rem 1.5rem;
      color: $white;

      &:focus {
        box-shadow: 0 0 0 0.1rem $light-blue;
        background-color: #0f1b2f;
        color: $white;
      }
    }

    span {
      font-size: 1.6rem;
      position: absolute;
      right: 2rem;
      top: 1.1rem;
    }
  }
}
